import { CircularProgress } from "react-loading-indicators";
import { css } from '@emotion/react';
import { memo, useCallback, useEffect, useRef } from 'react';
import { navigate } from '@reach/router';
import { useUpdate, useMount, useWindowSize } from 'react-use';
import Viewer from 'react-viewer';

import
{
	COLOR_FANZTOK_MAIN,
	COLOR_GRADATION,
	CONTENT_WIDTH,
	MENU_WIDTH,
	HEADER_HEIGHT,
	COLOR_ULTRA_LIGHT_GRAY,
} from '../utility/const';
import
{
	getCategoryContent,
	getClientWidth,
	getFanzaContentInfo,
	getFanzaMovie,
	getMemoryStorage,
	getUrlParams,
	isSmartphone,
	shuffleArray,
	sleep,
	stringToJson,
	supabaseGetFanzaActoressInfo,
	toInteger,
} from '../utility/utility';
import { Event } from '../utility/event';
import { useLocalState } from '../hook/use-local-state';
import { Dialog } from './dialog';
import { Footer } from './footer';
import { EmbedTweet } from './embed-tweet';
import { CategoryMenu } from './category-menu';
import { SideMenu } from './side-menu';
import logoInsta from '../assets/logo-instagram.png'
import logoTiktok from '../assets/logo-tiktok.png'
import logoTwitter from '../assets/logo-twitter.png'
import logoYoutube from '../assets/logo-youtube.png'

const Layout = ( params: any ) =>
{
	const paragrahSign = `● `;

	return (
		<div css={params.style}>
			<div className={`detail-base`}>
				{ params.info !== null &&
				<>
					<h1 className={`detail-title`} id={`detail-title`}>{params.info.title}</h1>
					<img className={`detail-package-image`} src={params.info.package_image_detail_url} alt={``} loading={`lazy`} content-visibility={`auto`} data-index={-1} onClick={params.onClickPhoto} />
					<div className={`detail-sample-base`}>
						{params.info.sample_image_urls.map( ( item: any, index: number ) => <img key={index} className={`detail-sample-image`} src={item} alt={``} loading={`lazy`} content-visibility={`auto`} data-index={index} onClick={params.onClickPhoto} /> )}
					</div>
					{ params.info.wiki !== `` &&
						<>
							<div className={`detail-panel-actoress`}>
								<div className={`detail-section-title`}>{paragrahSign}{params.info.actoress[ 0 ].name}ちゃんはこんな人</div>
							</div>
							<div className={`detail-panel-wiki`}>
								<blockquote className={`detail-wiki`}>
									<div className={`detail-wiki-panel`}>
										{params.info.wiki.texts.map( ( item: any, index: number ) => <div key={index}>{item}</div> )}
									</div>
									{ params.info.wiki.ruby !== `` &&
										<div className={`detail-info-line`}>
											<span className={`detail-info-item4`}>読み仮名</span>
											<span className={`detail-info-item5`}>{params.info.wiki.ruby}</span>
										</div>
									}
									{ params.info.wiki.nickname !== `` &&
										<div className={`detail-info-line`}>
											<span className={`detail-info-item4`}>愛称</span>
											<span className={`detail-info-item5`}>{params.info.wiki.nickname}</span>
										</div>
									}
									{ params.info.wiki.other_name !== `` &&
										<div className={`detail-info-line`}>
											<span className={`detail-info-item4`}>別名</span>
											<span className={`detail-info-item5`}>{params.info.wiki.other_name}</span>
										</div>
									}
									{ ( params.info.wiki.born_year !== `` && params.info.wiki.born_month !== `` && params.info.wiki.born_day !== `` && 0 < params.info.wiki.born_year ) &&
										<div className={`detail-info-line`}>
											<span className={`detail-info-item4`}>生年月日</span>
											<span className={`detail-info-item5`}>{params.info.wiki.born_year}年{params.info.wiki.born_month}月{params.info.wiki.born_day}日</span>
										</div>
									}
									{ params.info.wiki.born_place !== `` &&
										<div className={`detail-info-line`}>
											<span className={`detail-info-item4`}>出身地</span>
											<span className={`detail-info-item5`}>{params.info.wiki.born_place}</span>
										</div>
									}
									{ params.info.wiki.blood_type !== `` &&
										<div className={`detail-info-line`}>
											<span className={`detail-info-item4`}>血液型</span>
											<span className={`detail-info-item5`}>{params.info.wiki.blood_type}</span>
										</div>
									}
									{ ( params.info.wiki.height !== `` && params.info.wiki.weight !== `` && 0 < params.info.wiki.height ) &&
										<div className={`detail-info-line`}>
											<span className={`detail-info-item4`}>身長、体重</span>
											<span className={`detail-info-item5`}>{params.info.wiki.height} cm、{params.info.wiki.weight} kg</span>
										</div>
									}
									{ ( params.info.wiki.bust !== `` && params.info.wiki.weist !== `` && params.info.wiki.hip !== `` && 0 < params.info.wiki.bust ) &&
										<div className={`detail-info-line`}>
											<span className={`detail-info-item4`}>バスト、ウェスト、ヒップ</span>
											<span className={`detail-info-item5`}>{params.info.wiki.bust} x {params.info.wiki.weist} x {params.info.wiki.hip} cm</span>
										</div>
									}
									{ params.info.wiki.cup !== `` &&
										<div className={`detail-info-line`}>
											<span className={`detail-info-item4`}>カップ</span>
											<span className={`detail-info-item5`}>{params.info.wiki.cup}</span>
										</div>
									}
									{ params.info.wiki.start_date !== `` &&
										<div className={`detail-info-line`}>
											<span className={`detail-info-item4`}>活動開始</span>
											<span className={`detail-info-item5`}>{params.info.wiki.start_date.replace( `-`, `〜` )}</span>
										</div>
									}
									<footer>引用元：
										<cite>
											<a className={`detail-refer-link`} href={params.info.wiki.url} target={`_blank`} rel={`noopener noreferrer`}>Wikipedia</a>
										</cite>
									</footer>
								</blockquote>
							</div>
						</>
					}
					<video ref={params.videoRef} className={`detail-video`} src={`${params.info.sample_moviel_url}#t=30`} poster={params.images[ 0 ].src} preload={`auto`} playsInline controls />
					<div className={`detail-panel-description`}>
						<div className={`detail-section-title`}>{paragrahSign}商品コメント</div>
						<div className={`detail-description`}>{params.info.description}</div>
					</div>
					<div className={`detail-panel-info`}>
						<div className={`detail-section-title`}>{paragrahSign}商品情報</div>
						<div className={`detail-description`}>
							{ params.info.actoress instanceof Array === true &&
								<div className={`detail-info-line`}>
									<span className={`detail-info-item1`}>出演者</span>
									<span className={`detail-info-item2`}>
										<ul className={`detail-info-list`}>
											{ params.info.actoress.map( ( item: any, index: number ) => <span key={index} className={`detail-info-list-item`}>{item.name}</span> ) }
										</ul>
									</span>
								</div>
							}
							{ params.info.genres instanceof Array === true &&
								<div className={`detail-info-line`}>
									<span className={`detail-info-item1`}>ジャンル</span>
									<span className={`detail-info-item2`}>
										<ul className={`detail-info-list`}>
											{ params.info.genres.map( ( item: any, index: number ) => <li key={index} className={`detail-info-list-item`}>{item.name}</li> ) }
										</ul>
									</span>
								</div>
							}
							{ params.info.series instanceof Array === true &&
								<div className={`detail-info-line`}>
									<span className={`detail-info-item1`}>シリーズ</span>
									<span className={`detail-info-item2`}>
										{ params.info.series.map( ( item: any, index: number ) => <li key={index} className={`detail-info-list-item`}>{item.name}</li> ) }
									</span>
								</div>
							}
							{ params.info.makers instanceof Array === true &&
								<div className={`detail-info-line`}>
									<span className={`detail-info-item1`}>メーカー</span>
									<span className={`detail-info-item2`}>
										{ params.info.makers.map( ( item: any, index: number ) => <li key={index} className={`detail-info-list-item`}>{item.name}</li> ) }
									</span>
								</div>
							}
							{ params.info.labels instanceof Array === true &&
								<div className={`detail-info-line`}>
									<span className={`detail-info-item1`}>レーベル</span>
									<span className={`detail-info-item2`}>
										{ params.info.labels.map( ( item: any, index: number ) => <li key={index} className={`detail-info-list-item`}>{item.name}</li> ) }
									</span>
								</div>
							}
							{ params.info.directors instanceof Array === true &&
								<div className={`detail-info-line`}>
									<span className={`detail-info-item1`}>監督</span>
									<span className={`detail-info-item2`}>
										{ params.info.directors.map( ( item: any, index: number ) => <li key={index} className={`detail-info-list-item`}>{item.name}</li> ) }
									</span>
								</div>
							}
							<div className={`detail-info-line`}>
								<span className={`detail-info-item1`}>発売開始</span>
								<span className={`detail-info-item2`}>{params.info.buy_date}</span>
							</div>
							<div className={`detail-info-line`}>
								<span className={`detail-info-item1`}>配信開始</span>
								<span className={`detail-info-item2`}>{params.info.publish_date}</span>
							</div>
							<div className={`detail-info-line`}>
								<span className={`detail-info-item1`}>収録時間</span>
								<span className={`detail-info-item2`}>{params.info.duration}</span>
							</div>
							<div className={`detail-info-line`}>
								<span className={`detail-info-item1`}>品番</span>
								<span className={`detail-info-item2`}>{params.info.sku.toUpperCase()}</span>
							</div>
							<div className={`detail-info-line`}>
								<span className={`detail-info-item1`}>視聴可能デバイス</span>
								<span className={`detail-info-item2`}>{params.info.devices}</span>
							</div>
							<div className={`detail-info-line`}>
								<span className={`detail-info-item1`}>平均評価</span>
								<span className={`detail-info-item2`}>
									{Array.from( Array( Math.floor( params.info.average_rate ) ).keys() ).map( ( index ) => <span key={`${index}`} className={`detail-review-item-text1`}>&#x2b50;</span> )}
									<span className={`detail-review-item-text2`}>{params.info.average_rate}</span>
									<span className={`detail-review-item-text2`}>点</span>
								</span>
							</div>
						</div>
						<blockquote className={`detail-fanza`}>
							<footer>引用元：
								<cite>
									<a className={`detail-refer-link`} href={params.info.url} target={`_blank`} rel={`noopener noreferrer`}>FANZA</a>
								</cite>
							</footer>
						</blockquote>
					</div>
					<div className={`detail-panel-button`}>
						<button className={`button-purchase`} onClick={params.onClickPurchase}>DVDを購入する</button>
						<button className={`button-download`} onClick={params.onClickDownload}>動画をダウンロード</button>
					</div>
					{ 0 < params.info.fan_tweet_ids.length &&
						<div className={`detail-panel-fan`}>
							<div className={`detail-section-title`}>{paragrahSign}コアなファンの声</div>
							<div className={`detail-fan-padding`}></div>
							{params.info.fan_tweet_ids.map( ( url: string, index: number ) => <div key={index} className={`detail-fun-tweet`}><EmbedTweet tweetId={url} options={{lang: `ja`, align: `center`}} /></div> )}
						</div>
					}
					{ ( params.info.twitterId !== `` || params.info.tiktokId !== `` || params.info.youtubeId !== `` || params.info.instagramId !== `` ) &&
						<div className={`detail-panel-sns`}>
							<div className={`detail-section-title`}>{paragrahSign}他の活動を見にイク</div>
							<div className={`detail-description`}>
								{ params.info.twitterId !== `` &&
									<a href={`https://twitter.com/${params.info.twitterId}`} target={`_blank`} rel={`noopener noreferrer`}>
										<img src={logoTwitter} className={`detail-sns-logo`} alt={`logo`} /><span className={`detail-sns-text`}>Twitter</span>
									</a>
								}
								{ params.info.tiktokId !== `` &&
									<a href={`https://www.tiktok.com/@${params.info.tiktokId}`} target={`_blank`} rel={`noopener noreferrer`}>
										<img src={logoTiktok} className={`detail-sns-logo`} alt={`logo`} /><span className={`detail-sns-text`}>Tiktok</span>
									</a>
								}
								{ params.info.instagramId !== `` &&
									<a href={`https://www.instagram.com/${params.info.instagramId}`} target={`_blank`} rel={`noopener noreferrer`}>
										<img src={logoInsta} className={`detail-sns-logo`} alt={`logo`} /><span className={`detail-sns-text`}>Insta</span>
									</a>
								}
								{ params.info.youtubeId !== `` &&
									<a href={`https://www.youtube.com/channel/${params.info.youtubeId}`} target={`_blank`} rel={`noopener noreferrer`}>
										<img src={logoYoutube} className={`detail-sns-logo`} alt={`logo`} /><span className={`detail-sns-text`}>Youtube</span>
									</a>
								}
							</div>
						</div>
					}
					<div className={`detail-panel-other`}>
						{params.timeline}
					</div>
					{ 0 < params.info.fanza_movies.length &&
						<div className={`detail-other-contents-base`}>
							<div className={`detail-section-title`}>{paragrahSign}他の作品も見る</div>
							<div className={`detail-other-contents`}>
								{params.info.fanza_movies.map( ( item: any, index: number ) => <img key={index} className={`detail-other-contents-image`} src={item.image_url} alt={``} loading={`lazy`} content-visibility={`auto`} data-id={item.id} onClick={params.onClickOther} /> )}
							</div>
						</div>
					}
					<div className={`detail-panel-support`}>
						<div className={`detail-section-title`}>{paragrahSign}{params.info.actoress[ 0 ].name}ちゃんを応援しよう！😍</div>
						<div className={`detail-tweet-button-base`}>
							<a className={`detail-tweet-button`} href={`https://twitter.com/intent/tweet?${params.shareText}`} data-size={`large`} target={`_blank`}>応援する</a>
						</div>
					</div>
					<Footer />
				</>
				}
			</div>
			<Dialog />
			<Viewer
				visible={params.showViewer}
				activeIndex={params.activeIndex}
				downloadable={true}
				rotatable={false}
				scalable={false}
				attribute={false}
				loop={false}
				zoomSpeed={0.5}
				minScale={1.0}
				onClose={params.onClickClose}
				images={params.images}
			/>
			<CategoryMenu />
			<SideMenu />
		</div>
	);
};

const Style = ( params: any ) => css`
	display					: flex;
	justify-content			: center;
	position				: relative;
	width					: 100%;
	height					: 100%;

	.detail-base
	{
		position			: relative;
		top					: 0px;
		width				: 100%;
		max-width			: ${CONTENT_WIDTH}px;
		height				: 100%;
		overflow-x			: hidden;
		overflow-y			: auto;
		background-color	: ${COLOR_ULTRA_LIGHT_GRAY};
	}
	.detail-panel-description
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-top		: 8px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-top		: 12px;
		}
	}
	.detail-panel-actoress
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-top		: 12px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-top		: 20px;
		}
	}
	.detail-panel-wiki
	{
	}
	.detail-panel-spec
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-top		: 8px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-top		: 12px;
		}
	}
	.detail-panel-sns
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-top		: 16px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-top		: 16px;
		}
	}
	.detail-panel-fan
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-top		: 20px;
			padding-bottom	: 12px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-top		: 24px;
			padding-bottom	: 24px;
		}
		background-color	: #f6f6f6;
	}
	.detail-panel-other
	{
	}
	.detail-panel-support
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-top		: 12px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-top		: 24px;
		}
	}
	.detail-panel-info
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-top		: 8px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-top		: 16px;
		}
	}
	.detail-panel-button
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-top		: 0px;
			padding-bottom	: 24px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-top		: 16px;
			padding-bottom	: 48px;
		}
		text-align			: center;
	}
	.button-purchase
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-left	: 16px;
			padding-right	: 16px;
			margin-right	: 8px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-left	: 48px;
			padding-right	: 48px;
			margin-right	: 16px;
		}
		transform			: scale( 1.0 );
		transition			: all 0.1s 0s ease;
		background-color	: ${COLOR_FANZTOK_MAIN};
		color				: white;
		font-weight			: bold;
	}
	.button-purchase:hover
	{
		transform			: scale( 1.03 );
	}
	.button-download
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-left	: 16px;
			padding-right	: 16px;
			margin-left		: 8px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-left	: 48px;
			padding-right	: 48px;
			margin-left		: 16px;
		}
		transform			: scale( 1.0 );
		transition			: all 0.1s 0s ease;
		background-color	: ${COLOR_GRADATION};
		color				: white;
		font-weight			: bold;
	}
	.button-download:hover
	{
		transform			: scale( 1.03 );
	}
	.detail-title
	{
		padding-left		: var( --sidePadding );
		padding-right		: var( --sidePadding );
		padding-top			: calc( ${HEADER_HEIGHT}px + 16px );
		padding-bottom		: 16px;
		line-height			: 32px;
		font-size			: 20px;
		font-weight			: bold;
		color				: white;
		background			: linear-gradient( to right, ${COLOR_FANZTOK_MAIN}, ${COLOR_GRADATION} );
	}
	.detail-package-image
	{
		position			: relative;
		width				: 100%;
		object-fit			: cover;
		cursor				: pointer;
	}
	.detail-sample-base
	{
		position			: relative;
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			height			: 64px;
			padding-top		: 4px;
		}
		@media screen and ( min-width : 650px )
		{
			height			: 96px;
			padding-top		: 8px;
		}
		margin				: 0;
		overflow-x			: auto;
		overflow-y			: hidden;
		white-space			: nowrap;
		/* -webkit-overflow-scrolling : touch; */
	}
	.detail-sample-image
	{
		position			: relative;
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			height			: 64px;
		}
		@media screen and ( min-width : 650px )
		{
			height			: 96px;
		}
		aspect-ratio		: 16 / 10;
		object-fit			: cover;
		cursor				: pointer;
		transform			: scale( 1.0 );
		transition			: all 0.1s 0s ease;
	}
	.detail-sample-image:not(:last-of-type)
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-right	: 8px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-right	: 12px;
		}
	}
	.detail-sample-image:hover
	{
		transform			: scale( 1.05 );
	}
	.detail-section-title
	{
		position			: relative;
		padding-left		: var( --sidePadding );
		padding-right		: var( --sidePadding );
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			font-size		: 16px;
		}
		@media screen and ( min-width : 650px )
		{
			font-size		: 18px;
		}
		font-weight			: 500;
	}
	.detail-description
	{
		position			: relative;
		padding-left		: var( --sidePadding );
		padding-right		: var( --sidePadding );
		padding-top			: 12px;
		padding-bottom		: 12px;
		font-size			: 14px;
		line-height			: 22px;
	}
	.detail-description-item
	{
		position			: relative;
		padding-left		: var( --sidePadding );
		padding-right		: var( --sidePadding );
		padding-top			: 8px;
		font-size			: 14px;
		line-height			: 22px;
	}
	.detail-wiki
	{
		position			: relative;
		height				: 100%;
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			width			: calc( 100% - 40px - 40px );
		}
		@media screen and ( min-width : 650px )
		{
			width			: calc( 100% - 60px - 60px );
		}
	}
	.detail-wiki-panel
	{
		position			: relative;
		line-height			: 26px;
		border-radius		: 12px;
		background-color	: #F5F5F5;
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			width			: calc( 100% + 34px );
			left			: -30px;
			padding			: 12px;
			font-size		: 12px;
		}
		@media screen and ( min-width : 650px )
		{
			width			: calc( 100% + 30px );
			left			: -20px;
			padding			: 24px;
			font-size		: 14px;
		}
		margin-bottom		: 16px;
	}
	.detail-wiki>footer
	{
		position			: relative;
		text-align			: right;
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			width			: calc( 100% + 34px + 8px );
			left			: -30px;
			padding-left	: 12px;
		}
		@media screen and ( min-width : 650px )
		{
			width			: calc( 100% + 30px - 16px );
			padding-left	: 24px;
		}
		margin-top			: 16px;
	}
	.detail-fan-padding
	{
		padding-top			: 10px;
	}
	.twitter-timeline-base
	{
		display				: flex;
		justify-content		: center;
		position			: relative;
		min-height			: 32px;
	}
	.twitter-timeline-loading
	{
		position			: absolute;
		left				: 50%;
		top					: 45%;
		transform			: translateX( -50% ) translateY( -50% );
	}
	.detail-tweet-button-base
	{
		padding-top			: 16px;
		padding-bottom		: 24px;
		text-align			: center;
	}
	.detail-tweet-button
	{
		background			: #1b95e0;
		border-radius		: 32px;
		color				: #fff;
		display				: inline-block;
		font-size			: 16px;
		font-weight			: bold;
		margin-left			: 0px;
		padding				: 8px 16px 8px 16px;
		vertical-align		: bottom;
		width				: 200px;
		&::before
		{
			background		: transparent 0 0 no-repeat;
			background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2072%2072%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h72v72H0z%22%2F%3E%3Cpath%20class%3D%22icon%22%20fill%3D%22%23fff%22%20d%3D%22M68.812%2015.14c-2.348%201.04-4.87%201.744-7.52%202.06%202.704-1.62%204.78-4.186%205.757-7.243-2.53%201.5-5.33%202.592-8.314%203.176C56.35%2010.59%2052.948%209%2049.182%209c-7.23%200-13.092%205.86-13.092%2013.093%200%201.026.118%202.02.338%202.98C25.543%2024.527%2015.9%2019.318%209.44%2011.396c-1.125%201.936-1.77%204.184-1.77%206.58%200%204.543%202.312%208.552%205.824%2010.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163%200%206.345%204.513%2011.638%2010.504%2012.84-1.1.298-2.256.457-3.45.457-.845%200-1.666-.078-2.464-.23%201.667%205.2%206.5%208.985%2012.23%209.09-4.482%203.51-10.13%205.605-16.26%205.605-1.055%200-2.096-.06-3.122-.184%205.794%203.717%2012.676%205.882%2020.067%205.882%2024.083%200%2037.25-19.95%2037.25-37.25%200-.565-.013-1.133-.038-1.693%202.558-1.847%204.778-4.15%206.532-6.774z%22%2F%3E%3C%2Fsvg%3E);
			content			: "";
			display			: inline-block;
			height			: 16px;
			padding-right	: 8px;
			position		: relative;
			top				: 4px;
			width			: 16px;
			vertical-align	: top;
		}
		transform			: scale( 1.0 );
		transition			: all 0.1s 0s ease;
	}
	.detail-tweet-button:hover
	{
		transform			: scale( 1.05 );
	}
	.detail-fun-tweet
	{
		position			: relative;
		width				: calc( 100% - var( --sidePadding ) - var( --sidePadding ) );
		padding-left		: var( --sidePadding );
		padding-right		: var( --sidePadding );
	}
	.detail-video
	{
		position			: relative;
		width				: 100%;
		max-width			: var( --inner-width );
		padding-top			: 10px;
		aspect-ratio		: 1.7;
		content-visibility		: auto;
		contain-intrinsic-size	: 400px;
	}
	.detail-info-line
	{
		position			: relative;
		display				: flex;
		min-height			: 36px;
		line-height			: 28px;
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			font-size		: 13px;
		}
		@media screen and ( min-width : 650px )
		{
			font-size		: 14px;
		}
	}
	.detail-info-item1
	{
		position			: relative;
		min-width			: 120px;
	}
	.detail-info-item2
	{
		position			: relative;
	}
	.detail-info-item3
	{
		position			: relative;
		border				: 1px #ccc solid;
		border-radius		: 3px;
		margin				: 0px 4px 8px 0px;
		padding				: 6px 8px 6px 8px;
		font-size			: 12px;
	}
	.detail-info-item4
	{
		position			: relative;
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			min-width		: 160px;
			left			: -24px;
		}
		@media screen and ( min-width : 650px )
		{
			min-width		: 200px;
			left			: 0px;
		}
	}
	.detail-info-item5
	{
		position			: relative;
	}
	.detail-info-list
	{
		display				: inline;
		justify-content		: flex-start;
		text-align			: left;
		position			: relative;
		width				: 100%;
		padding				: 0px;
		margin				: 0px;
	}
	.detail-info-list-item
	{
		display				: inline-block;
		border				: 1px #ccc solid;
		border-radius		: 3px;
		margin				: 0px 4px 6px 0px;
		padding				: 0px 8px 0px 8px;
		font-size			: 12px;
	}
	.detail-fanza
	{
		position			: relative;
		height				: 100%;
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			width			: calc( 100% - 40px - 40px );
		}
		@media screen and ( min-width : 650px )
		{
			width			: calc( 100% - 60px - 60px );
		}
	}
	.detail-fanza>footer
	{
		position			: relative;
		text-align			: right;
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			width			: calc( 100% + 34px + 8px );
			left			: -30px;
			padding-left	: 12px;
		}
		@media screen and ( min-width : 650px )
		{
			width			: calc( 100% + 30px + 16px );
			left			: -16px;
			padding-left	: 24px;
		}
		margin-top			: 16px;
	}
	.detail-refer-link
	{
		cursor				: pointer;
		text-decoration		: none;
	}
	.detail-refer-link:hover
	{
		text-decoration		: underline;
	}
	.detail-sns-logo
	{
		position			: relative;
		padding-top			: 0px;
		padding-bottom		: 0px;
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-right	: 6px;
			height			: 24px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-right	: 12px;
			height			: 32px;
		}
		cursor				: pointer;
		transform			: scale( 1.0 );
		transition			: all 0.1s 0s ease;
	}
	.detail-sns-logo:hover
	{
		transform			: scale( 1.05 );
	}
	.detail-sns-text
	{
		position			: relative;
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-right	: 8px;
			top				: -7px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-right	: 16px;
			top				: -11px;
		}
		cursor				: pointer;
		text-decoration		: none;
	};
	.detail-sns-text:hover
	{
		text-decoration		: underline;
	}
	.detail-review-item-text1
	{
		margin-right		: 3px;
		font-size			: 20px;
	}
	.detail-review-item-text2
	{
		padding-left		: 4px;
		font-size			: 14px;
	}
	.detail-other-contents-base
	{
		padding-top			: 16px;
	}
	.detail-other-contents
	{
		display				: flex;
		flex-direction		: column;
		padding-top			: 12px;
	}
	.detail-other-contents-image
	{
		padding-top			: 8px;
		position			: relative;
		content-visibility	: auto;
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			height					: 128px;
			contain-intrinsic-size	: 128px;
		}
		@media screen and ( min-width : 650px )
		{
			height					: 200px;
			contain-intrinsic-size	: 200px;
		}
		aspect-ratio		: 16 / 10;
		object-fit			: cover;
		cursor				: pointer;
		transition			: all 0.1s 0.0s ease;
	}
	.disc-bounding-box .disc-loader .disc-ring
	{
		width				: 50%;
		height				: 50%;
	}
`;

export const Detail = memo( ( props: any ) =>
{
	const state = useLocalState(
	{
		showViewer		: false,
		images			: [],
		activeIndex		: 0,
		playCount		: 0,
		info			: null,
		shareText		: ``,
		timeline		: null,
	});
	const update = useUpdate();
	const videoRef	= useRef( null );
	const { width } = useWindowSize();

	const load = useCallback( async () =>
	{
		window.scrollTo( 0, 0 );
		await sleep( 100 );

		Event.publish( `SHOW_HEADER` );
		Event.publish( `SHOW_MENU`, {}, true );
		Event.publish( `TOGGLE_CONTENT_WIDTH`, { isFullContent: true }, true );

		const id = getUrlParams( `id` );
		if( id === undefined )
		{
			navigate( `/timeline`, { state: {}, replace: false } );
			return;
		}


		const id_ = Number( id );
		const data = await getFanzaMovie( `detail-${id_}`, id_ );
		if( data === `` )
		{
console.log( `動画なかった？`, id_ )
			navigate( `/timeline`, { state: {}, replace: false } );
			return;
		}

		state.info = data;
		state.info.wiki				= ``;
		state.info.twitterId		= ``;
		state.info.tiktokId			= ``;
		state.info.youtubeId		= ``;
		state.info.instagramId		= ``;
		state.info.fanza_movies		= [];
		state.info.directors		= [];
		state.info.labels			= [];
		state.info.makers			= [];
		state.info.series			= [];
		state.info.genres			= [];
		state.info.actoress			= [];
		state.info.fan_tweet_ids	= [];

		for( const link of state.info.fanza_links )
		{
			switch( link.type )
			{
				case `actoress`	: state.info.actoress .push( link ); break;
				case `label`	: state.info.labels	  .push( link ); break;
				case `maker`	: state.info.makers	  .push( link ); break;
				case `series`	: state.info.series	  .push( link ); break;
				case `genre`	: state.info.genres	  .push( link ); break;
				case `director`	: state.info.directors.push( link ); break;
				default : break;
			}
		}



// 		let data = {};
// 		const data_ = getMemoryStorage( id );
// 		if( data_ )
// 		{
// 			data = stringToJson( data_ );

// 		}
// 		else
// 		{
// 			const item = await getCategoryContent( id );
// 			data = await getFanzaContentInfo( id, item[ 0 ].description );
// 		}

// // console.log( `AAA1`, data.sampleImageURL.sample_l.image )
// // console.log( `AAA2`, data.iteminfo )
// console.log( `AAA3`, data )

// 		state.info = {};
// 		state.info.id					= id;
// 		state.info.url					= data.URL;
// 		state.info.content_id			= id;
// 		state.info.package_image_url	= ``;
// 		state.info.package_image_detail_url	= data.sampleImageURL.sample_l.image[ 0 ];
// 		state.info.sample_moviel_url	= data.sampleMovieURL.size_720_480;

// console.log( `BBB` )
// console.log( data.sampleMovieURL.size_720_480 )

// // https://cc3001.dmm.co.jp/litevideo/freepv/m/mdo/mdon00031/mdon00031_sm_w.mp4

// // https://www.dmm.co.jp/litevideo/-/part/=/cid=mdon00031/size=720_480/affi_id=fanztok-990/


// 		state.info.sample_image_urls	= data.sampleImageURL.sample_l.image;
// 		state.info.average_rate			= data.review.average;
// 		state.info.review_count			= data.review.count;
// 		state.info.actoress_count		= 0;
// 		state.info.good_level			= 0;
// 		state.info.devices				= ``;
// 		state.info.publish_date			= data.date;
// 		state.info.buy_date				= ``;
// 		state.info.duration				= ``;
// 		state.info.sku					= id;
// 		state.info.fanza_link_ids		= [];
// 		state.info.status				= ``;
// 		state.info.fanza_links			= {};
// 		state.info.title				= data.title;
// 		state.info.description			= data.description;
// 		state.info.wiki					= ``;
// 		state.info.twitterId			= ``;
// 		state.info.tiktokId				= ``;
// 		state.info.youtubeId			= ``;
// 		state.info.instagramId			= ``;
// 		state.info.fanza_movies			= [];
// 		state.info.directors			= [];
// 		state.info.labels				= [];
// 		state.info.makers				= [];
// 		state.info.series				= [];
// 		state.info.genres				= [];
// 		state.info.actoress				= [];
// 		state.info.fan_tweet_ids		= [];

		state.images = [];
		state.images.push( { src: state.info.package_image_detail_url } );
		for( let i = 0; i < state.info.sample_image_urls.length; i++ )
		{
			const url = state.info.sample_image_urls[ i ];
			if( url.includes( `-${i+1}` ) )
			{
				state.images.push( { src: url.replace( `-${i+1}`, `jp-${i+1}` ) } );
			}
			else
			{
				state.images.push( { src: url } );
			}
		}

		if( 0 < state.info.actoress.length )
		{
			const fanzaActoressId = state.info.actoress[ 0 ].target_id;	// 最初の女優を採用する
			const info = await supabaseGetFanzaActoressInfo( fanzaActoressId );
			if( 0 < info.length )
			{
				if( info[ 0 ].wiki )
				{
					state.info.wiki = info[ 0 ].wiki[ 0 ];
				}
				if( info[ 0 ].links )
				{
					for( const link of info[ 0 ].links )
					{
						if( link.target === `twitter` )
						{
							state.info.twitterId = link.user_id;
						}
						else if( link.target === `tiktok` )
						{
							state.info.tiktokId = link.user_id;
						}
						else if( link.target === `youtube` )
						{
							state.info.youtubeId = link.user_id;
						}
						else if( link.target === `instagram` )
						{
							state.info.instagramId = link.user_id;
						}
					}
				}
				if( info[ 0 ].fanza_movies )
				{
					state.info.fanza_movies = shuffleArray( info[ 0 ].fanza_movies ).splice( 0, 5 );
				}
			}

			if( state.info.twitterId !== `` )
			{
				const maxWidth	  = Math.min( CONTENT_WIDTH, getClientWidth() ) - 16 - 16;
				const placeholder = <div className={`twitter-timeline-loading`}><CircularProgress color={`#bdbdbd`} size={`small`}/></div>;
				state.timeline	  = <div className={`twitter-timeline-base`}><a className={`twitter-timeline`} href={`https://twitter.com/${state.info.twitterId}`} data-lang={`ja`} data-width={maxWidth}>{placeholder}</a></div>
			}
		}

		// if( data.iteminfo.actress )
		// {
		// 	for( const item of data.iteminfo.actress )
		// 	{
		// 		state.info.actoress.push( item );
		// 	}
		// }
		// if( data.iteminfo.label )
		// {
		// 	for( const item of data.iteminfo.label )
		// 	{
		// 		state.info.labels.push( item );
		// 	}
		// }
		// if( data.iteminfo.maker )
		// {
		// 	for( const item of data.iteminfo.maker )
		// 	{
		// 		state.info.makers.push( item );
		// 	}
		// }
		// if( data.iteminfo.series )
		// {
		// 	for( const item of data.iteminfo.series )
		// 	{
		// 		state.info.series.push( item );
		// 	}
		// }
		// if( data.iteminfo.genre )
		// {
		// 	for( const item of data.iteminfo.genre )
		// 	{
		// 		state.info.genres.push( item );
		// 	}
		// }
		// if( data.iteminfo.director )
		// {
		// 	for( const item of data.iteminfo.director )
		// 	{
		// 		state.info.directors.push( item );
		// 	}
		// }

// ファンのツイートをピックアップ
		// state.info.fan_tweet_ids =
		// [
		// 	'1588749748500328450',
		// 	'1588749322652614658',
		// 	'1588837735506530305',
		// ];

		// const affiliateParams = `?utm_medium=dmm_affiliate&utm_source=fanztok-001&utm_campaign=affiliate_toolbar&utm_content=link`;
		// const contentUrl = `${state.info.url}${affiliateParams}`;
		const affiliateParams = encodeURI( `${state.info.url}&af_id=fanztok-001&ch=toolbar&ch_id=link` );
		const contentUrl = `https://al.dmm.co.jp/?lurl=${affiliateParams}`;
		state.info.contentUrlP = contentUrl;
		state.info.contentUrlD = contentUrl;
		// state.info.contentUrlP = data.affiliateURL;
		// state.info.contentUrlD = data.affiliateURL;
		state.info.url = contentUrl;

		// twitter web intent を設定する
		const name		= state.info.actoress[ 0 ].name;
		const title		= state.info.title;
// あれば、バズってるワード
		// const text		= `あいおえお\n`;
		const text		= ``;
		const hashtags	= `${name},${title},fanztok`;
		state.shareText = encodeURI( `text=${text}&url=${contentUrl}&hashtags=${hashtags}` );

		// // 高画質版のパスに変更
		state.info.sample_moviel_url = state.info.sample_moviel_url.replace( `sm_w`, `dmb_w` );

		update();
	}, [] );

	const onClickTimeline = useCallback( () =>
	{
		navigate( `/timeline`, { state: {}, replace: false } );
	}, [] );

	const onClickPurchase = useCallback( () =>
	{
		window.open( state.info.contentUrlP );
	}, [] );

	const onClickDownload = useCallback( () =>
	{
		window.open( state.info.contentUrlD );
	}, [] );

	const onClickPhoto = useCallback( ( e: any ) =>
	{
		state.showViewer  = true;
		state.activeIndex = toInteger( e?.currentTarget?.dataset?.index ) + 1;
		update();
	}, [ state.showViewer, update ] );

	const onClickOther = useCallback( ( e: any ) =>
	{
		const id = e?.currentTarget?.dataset?.id;
		navigate( `/detail?id=${id}`, { state: { id: id, from: `detail` }, replace: false } );
	}, [] );

	const onClickClose = useCallback( () =>
	{
		state.showViewer = false;
		update();
	}, [ state.showViewer, update ] );

	useEffect( () =>
	{
		const f = () =>
		{
			state.playCount++;
			if( state.playCount < 3 )
			{
				return;
			}
			Event.publish( `SHOW_DIALOG`,
			{
				title		: state.info.title,
				imageUrl	: state.info.package_image_detail_url,
				contentUrlP	: state.info.contentUrlP,
				contentUrlD	: state.info.contentUrlD,
			})
		};

		const video: any = videoRef.current;
		video?.addEventListener( `ended`, f );

		return () =>
		{
			video?.removeEventListener( `ended`, f );
		};

	}, [ videoRef.current, state.playCount ] );

	useMount( async () =>
	{
		if( isSmartphone() === false )
		{
			if( CONTENT_WIDTH + MENU_WIDTH * 2 <= getClientWidth() )
			{
				setTimeout( () =>
				{
					Event.publish( `PUSH_MENU_BUTTON` );
					Event.publish( `PUSH_SIDE_MENU_BUTTON` );
				}, 5000 );
			}
		}

		load();
	});

	if( state.info === null )
	{
		return null;
	}

	const styleParams =
	{
		width			: width,
	};
	const params =
	{
		style			: Style( styleParams ),
		info			: state.info,
		showViewer		: state.showViewer,
		images			: state.images,
		activeIndex		: state.activeIndex,
		shareText		: state.shareText,
		timeline		: state.timeline,
		videoRef		: videoRef,
		onClickPurchase	: onClickPurchase,
		onClickDownload	: onClickDownload,
		onClickTimeline	: onClickTimeline,
		onClickPhoto	: onClickPhoto,
		onClickOther	: onClickOther,
		onClickClose	: onClickClose,
	};
	return ( <Layout {...params} /> );
});
