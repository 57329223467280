import { css } from '@emotion/react';
import { memo } from 'react';

import
{
	CONTENT_WIDTH,
	COLOR_BLACK,
	COLOR_FANZTOK_MAIN,
	URL_INQUIRY,
	URL_NOTICE,
	URL_PRIVACY,
	URL_TERM,
	URL_TWITTER,
} from '../utility/const';
import logo from '../assets/fanz-tok-logo-b.svg'

const Layout = ( params: any ) =>
{
	return (
		<div css={params.style}>
			<div className={`detail-panel-footer`}>
				<div className={`detail-footer-base`}>
					<div className={`detail-footer`}>
						<a href={`/`} rel={`noopener noreferrer`}>
							<img src={logo} className={`detail-footer-logo-image`} alt={`logo`} />
						</a>
						<ul className={`detail-footer-list`}>
							<li className={`detail-footer-item`}><a className={`detail-footer-link`} href={URL_NOTICE} target={`_blank`} rel={`noopener noreferrer`}>お知らせ</a></li>
							{/* <li className={`detail-footer-item`}><a className={`detail-footer-link`} href={URL_TERM} target={`_blank`} rel={`noopener noreferrer`}>利用規約</a></li>
							<li className={`detail-footer-item`}><a className={`detail-footer-link`} href={URL_PRIVACY} target={`_blank`} rel={`noopener noreferrer`}>プライバシーポリシー</a></li> */}
							<li className={`detail-footer-item`}><a className={`detail-footer-link`} href={URL_INQUIRY} target={`_blank`} rel={`noopener noreferrer`}>お問い合わせ</a></li>
							<li className={`detail-footer-item`}><a className={`detail-footer-link`} href={URL_TWITTER} target={`_blank`} rel={`noopener noreferrer`}>公式ツイッター</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

const Style = ( params: any ) => css`
	position				: relative;
	width					: ${params.width}px;
	max-width				: ${CONTENT_WIDTH}px;
	/* padding-bottom			: calc(env(safe-area-inset-bottom) + 0px); */
	background-color		: white;

	.detail-panel-footer
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-top		: 8px;
			height			: 100px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-top		: 16px;
			height			: 100px;
		}
	}
	.detail-footer-base
	{
		background-color	: ${COLOR_BLACK};
		color				: white;
		text-align			: center;
		position			: relative;
		height				: 100%;
		display				: flex;
		justify-content		: center;
		align-items			: center;
	}
	.detail-footer
	{
		display				: block;
	}
	.detail-footer-logo-image
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-top		: 8px;
			padding-bottom	: 0px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-top		: 10px;
			padding-bottom	: 5px;
		}
		position			: relative;
		height				: 16px;
	}
	.detail-footer-list
	{
		padding				: 0px;
		margin				: 0px;
		position			: relative;
		height				: 100%;
		font-size			: 14px;
	}
	.detail-footer-item
	{
		margin-left			: 10px;
		margin-right		: 10px;
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			margin-top		: 0px;
			margin-bottom	: 2px;
		}
		@media screen and ( min-width : 650px )
		{
			margin-top		: 5px;
			margin-bottom	: 5px;
		}
		display				: inline-block;
	};
	.detail-footer-link
	{
		color				: white;
		text-decoration		: none;
	};
	.detail-footer-link:hover
	{
		color				: ${COLOR_FANZTOK_MAIN};
		text-decoration		: underline;
	};
`;

export const Footer = memo( ( props: any ) =>
{
	const styleParams =
	{
	};
	const params =
	{
		style	: Style( styleParams ),
	};
	return ( <Layout {...params} /> );
});
