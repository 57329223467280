import { css } from '@emotion/react';
import { memo, useCallback } from 'react';
import { useUpdate, useMount, useWindowSize } from 'react-use';
import Viewer from 'react-viewer';
import { v4 as uuidv4 } from 'uuid';

import
{
	CONTENT_WIDTH,
	MENU_WIDTH,
	COLOR_FANZTOK_MAIN,
	COLOR_GRADATION,
	COLOR_ULTRA_LIGHT_GRAY,
} from '../utility/const';
import
{
	getClientWidth,
	isSmartphone,
} from '../utility/utility';
import { Event } from '../utility/event';
import { useLocalState } from '../hook/use-local-state';
import { Dialog } from './dialog';
import { Footer } from './footer';
import { CatchImage } from './catch-image';
import { EmbedTweet } from './embed-tweet';
import { CategoryMenu } from './category-menu';
import { SideMenu } from './side-menu';

const caption = `人類社会のすべての構成員の固有の尊厳と平等で譲ることのできない権利とを承認することは`;
const url = `https://pics.dmm.co.jp/digital/video/meyd00778/meyd00778pl.jpg`;
const items =
[
	{
		title		: `愛妻交換 幼馴染の妻と俺の妻を交換して中出ししまくった4日間の記録。 岬さくら・北乃ゆな`,
		imageUrl	: `https://pics.dmm.co.jp/digital/video/meyd00778/meyd00778pl.jpg`,
		movieUrl	: `https://cc3001.dmm.co.jp/litevideo/freepv/m/mey/meyd00778/meyd00778_sm_w.mp4`,
		tweetId		: `1592525316182114304`,
		embedId		: `1599116029644664833`,
		purchaseUrl	: `https://www.dmm.co.jp/digital/videoa/-/detail/=/cid=meyd00778/?i3_ref=list&i3_ord=2`,
		downloadUrl	: `https://www.dmm.co.jp/digital/videoa/-/detail/=/cid=meyd00778/?i3_ref=list&i3_ord=2`,
		name		: "朝倉ここな",
		iconUrl		: "https://pbs.twimg.com/profile_images/1517975953536090112/ragFBxwk.jpg",
		detailId	: 1,
	},
	{
		title		: `可愛い顔してデカ尻！！ 水原みその`,
		imageUrl	: `https://pics.dmm.co.jp/digital/video/mmkz00106/mmkz00106pl.jpg`,
		movieUrl	: `https://cc3001.dmm.co.jp/litevideo/freepv/m/mmk/mmkz106/mmkz106_sm_w.mp4`,
		tweetId		: `1572868432420536320`,
		embedId		: `1599115955791343616`,
		purchaseUrl	: `https://www.dmm.co.jp/digital/videoa/-/detail/=/cid=mmkz00106/?i3_ref=list&i3_ord=15`,
		downloadUrl	: `https://www.dmm.co.jp/digital/videoa/-/detail/=/cid=mmkz00106/?i3_ref=list&i3_ord=15`,
		name		: "天音ゆい Amane Yui",
		iconUrl		: "https://pbs.twimg.com/profile_images/1434571793579139072/Kf5p56On.jpg",
		detailId	: 2,
	},
	{
		title		: `「もうイッてるってばぁ！」状態で何度も中出し！ 倉本すみれ`,
		imageUrl	: `https://pics.dmm.co.jp/digital/video/waaa00195/waaa00195pl.jpg`,
		movieUrl	: `https://cc3001.dmm.co.jp/litevideo/freepv/w/waa/waaa00195/waaa00195_sm_w.mp4`,
		tweetId		: `1592731151655129089`,
		embedId		: `1599115153001549824`,
		purchaseUrl	: `https://www.dmm.co.jp/digital/videoa/-/detail/=/cid=waaa00195/?i3_ref=list&i3_ord=34`,
		downloadUrl	: `https://www.dmm.co.jp/digital/videoa/-/detail/=/cid=waaa00195/?i3_ref=list&i3_ord=34`,
		name		: "松岡すず🔔",
		iconUrl		: "https://pbs.twimg.com/profile_images/1581088668101451776/-e2cCBPv.jpg",
		detailId	: 3,
	},
];

const Layout = memo( ( params: any ) =>
{
	return (
		<div css={params.style}>
			<div className={`feature-base`}>

{/* この画像はパッケージの小さい方画像を横に３つ並べよう
向き合ってる写真は物語が起こりそうなことを予見させる */}


				<CatchImage url={params.url} caption={params.caption} items={params.userIcons} />
				{params.items}
				<Footer />
			</div>
			<Dialog />
			<Viewer
				visible={params.showViewer}
				activeIndex={params.activeIndex}
				downloadable={true}
				rotatable={false}
				scalable={false}
				attribute={false}
				loop={false}
				zoomSpeed={0.5}
				minScale={1.0}
				onClose={params.onClickClose}
				images={params.images}
			/>
			<CategoryMenu />
			<SideMenu />
		</div>
	);
});

const Style = ( params: any ) => css`
	display					: flex;
	justify-content			: center;
	position				: relative;
	height					: 100%;

	.feature-base
	{
		position			: relative;
		top					: 0px;
		width				: 100%;
		max-width			: ${CONTENT_WIDTH}px;
		height				: 100%;
		overflow-x			: hidden;
		overflow-y			: auto;
		background-color	: ${COLOR_ULTRA_LIGHT_GRAY};
	}
	.feature-item-base
	{
	}
	.feature-name
	{
		position			: relative;
		padding-left		: var( --sidePadding );
		padding-right		: var( --sidePadding );
		padding-top			: 32px;
		padding-bottom		: 16px;
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			font-size		: 18px;
		}
		@media screen and ( min-width : 650px )
		{
			font-size		: 24px;
		}
		font-weight			: bold;
		font-family			: 'M PLUS 1', sans-serif;
		border-top			: solid 1px #e7e7e7;
		text-align			: center;
		background			: -webkit-linear-gradient( 0deg, ${COLOR_FANZTOK_MAIN}, ${COLOR_GRADATION} );
		-webkit-background-clip	: text;
		-webkit-text-fill-color	: transparent;
	}
	.feature-tweet
	{
		position			: relative;
		width				: calc( 100% - var( --sidePadding ) - var( --sidePadding ) );
		padding-left		: var( --sidePadding );
		padding-right		: var( --sidePadding );
		padding-top			: 2px;
		padding-bottom		: 16px;
	}
	.feature-title
	{
		padding-left		: var( --sidePadding );
		padding-right		: var( --sidePadding );
		padding-top			: 16px;
		padding-bottom		: 16px;
		line-height			: 32px;
		font-size			: 20px;
		font-weight			: bold;
		color				: white;
		background			: linear-gradient( to right, ${COLOR_FANZTOK_MAIN}, ${COLOR_GRADATION} );
	}
	.feature-image
	{
		position			: relative;
		width				: 100%;
		max-width			: var( --inner-width );
		object-fit			: cover;
		cursor				: pointer;
	}
	.feature-video
	{
		position			: relative;
		width				: 100%;
		padding-top			: 10px;
	}
	.feature-button
	{
		padding-top			: 16px;
		padding-bottom		: 24px;
		text-align			: center;
	}
	.feature-purchase
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-left	: 16px;
			padding-right	: 16px;
			margin-right	: 8px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-left	: 48px;
			padding-right	: 48px;
			margin-right	: 16px;
		}
		transform			: scale( 1.0 );
		transition			: all 0.1s 0s ease;
		background-color	: ${COLOR_FANZTOK_MAIN};
		color				: white;
		font-weight			: bold;
	}
	.feature-purchase:hover
	{
		transform			: scale( 1.03 );
	}
	.feature-download
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-left	: 16px;
			padding-right	: 16px;
			margin-left		: 8px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-left	: 48px;
			padding-right	: 48px;
			margin-left		: 16px;
		}
		transform			: scale( 1.0 );
		transition			: all 0.1s 0s ease;
		background-color	: ${COLOR_GRADATION};
		color				: white;
		font-weight			: bold;
	}
	.feature-download:hover
	{
		transform			: scale( 1.03 );
	}
	.feature-detail-link
	{
		position			: relative;
		padding-left		: var( --sidePadding );
		padding-right		: var( --sidePadding );
		padding-top			: 16px;
		padding-bottom		: 16px;
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			font-size		: 12px;
		}
		@media screen and ( min-width : 650px )
		{
			font-size		: 16px;
		}
		text-align			: center;
		text-decoration		: underline;
		cursor				: pointer;
	}
	.feature-detail-link:hover
	{
		color				: ${COLOR_FANZTOK_MAIN};
	};
`;

export const Feature = memo( ( props: any ) =>
{
	const state = useLocalState(
	{
		scrollState		: ``,
		scrollOffset	: 0,
		items			: [],
		userIcons		: [],
	});
	const update = useUpdate();
	const { width } = useWindowSize();

	const onClickPurchase = useCallback( ( e: any ) =>
	{
		const index = e?.currentTarget?.dataset?.index;
		window.open( items[ index ].purchaseUrl );
	}, [] );

	const onClickDownload = useCallback( ( e: any ) =>
	{
		const index = e?.currentTarget?.dataset?.index;
		window.open( items[ index ].downloadUrl );
	}, [] );

	useMount( () =>
	{
		if( isSmartphone() === false )
		{
			if( CONTENT_WIDTH + MENU_WIDTH * 2 <= getClientWidth() )
			{
				setTimeout( () =>
				{
					Event.publish( `PUSH_MENU_BUTTON` );
					Event.publish( `PUSH_SIDE_MENU_BUTTON` );
				}, 5000 );
			}
		}

		Event.publish( `SHOW_MENU`, {}, true );
		Event.publish( `TOGGLE_CONTENT_WIDTH`, { isFullContent: true }, true );

		const key = uuidv4();
		let counter = 0;
		state.userIcons = [];
		state.items = [];
		for( const item of items )
		{
			const index = counter;
			state.items.push(
				<div className={`feature-item-base`} key={`${key}${counter++}`}>
					<div className={`feature-name`}>エピソード{index+1} {item.name}ちゃん</div>
					<div className={`feature-tweet`}><EmbedTweet options={{lang: `ja`, align: `center`}} placeholder={'読み込み中…'} tweetId={item.tweetId}/></div>
					<h1 className={`feature-title`} id={`feature-title`}>{item.title}</h1>
					<img className={`feature-image`} src={`${item.imageUrl}#t=30`} alt={``} loading={`lazy`} content-visibility={`auto`} data-index={index} onClick={params.onClickPurchase} />
					<video className={`feature-video`} src={`${item.movieUrl}#t=30`} preload={`auto`} playsInline controls />
					<div className={`feature-button`}>
						<button className={`feature-purchase`} data-index={index} onClick={params.onClickPurchase}>DVDを購入する</button>
						<button className={`feature-download`} data-index={index} onClick={params.onClickDownload}>動画をダウンロード</button>
						<div className={`feature-detail-link`}><a href={`/detail?id=${item.detailId}`} rel={`noopener noreferrer`}>{item.name}ちゃんはの作品の詳細はこちら</a></div>
					</div>
					<div className={`feature-tweet`}><EmbedTweet options={{lang: `ja`, align: `center`}} placeholder={'読み込み中…'} tweetId={item.embedId}/></div>
				</div>
			);
			state.userIcons.push( { name: item.name, url: item.iconUrl } );
		}
		update();
	});

	const styleParams =
	{
		width			: width,
	};
	const params =
	{
		style			: Style( styleParams ),
		url				: url,
		caption			: caption,
		userIcons		: state.userIcons,
		items			: state.items,
		onClickPurchase	: onClickPurchase,
		onClickDownload	: onClickDownload,
	};
	return ( <Layout {...params} /> );
});
