export const FONT_FAMILIY_DEFAULT		= [ `Hiragino Sans`,`Hiragino Kaku Gothic Pro`,`Meiryo`,`Helvetica Neue`,`Roboto`,`Droid Sans`,`Arial`,`sans-serif` ];

export const CONTENT_WIDTH				= 800;
export const BREAKPOINT_SP				= 650;
export const BREAKPOINT_TB				= 960;
export const HEADER_HEIGHT				= 40;
export const MENU_WIDTH					= 400;

export const COLOR_FANZTOK_MAIN			= `#FE005B`;
export const COLOR_TWITTER				= `#1DA1F2`;
export const COLOR_GRADATION			= `#E4A972`;
export const COLOR_BLACK				= `#14171A`;
export const COLOR_DARK_GRAY			= `#657786`;
export const COLOR_LIGHT_GRAY			= `#AAB8C2`;
export const COLOR_EXTRA_LIGHT_GRAY		= `#E1E8ED`;
export const COLOR_ULTRA_LIGHT_GRAY		= `#F5F8FA`;

export const URL_TWITTER				= `https://twitter.com/fanz_tok`;
export const URL_NOTICE					= `https://twitter.com/search?q=fanz_tokお知らせ`;
export const URL_TERM					= `https://www.google.co.jp`;
export const URL_PRIVACY				= `https://www.google.co.jp`;
export const URL_INQUIRY				= `https://twitter.com/fanz_tok`;

export const SCROLL_HANDLE_WIDTH		= 5;
export const HOME_TIMELINE_ITEM_COUNT	= 10;
export const TWEET_TIMELINE_ITEM_COUNT	= 50;

export const FANZ_TOK_AV_ACTORESS_TAG =
[
	`おはよう`,
	`おやすみ`,
	`こんにちは`,
	`こんばんは`,
	`お疲れ`,
	`感謝`,
	`感想`,
	`しよ`,
	`して`,
	`したい`,
	`した`,
	`してる`,
	`します`,
	`お願い`,
	`質問`,
	`鼓舞`,
	`食事`,
	`今日`,
	`告知`,
	`言葉遊び`,
	`ファッション`,
	`おっぱい`,
	`おしり`,
	`撮影会`,
	`xxの日`,
];