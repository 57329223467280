import 'normalize.css';
import { css } from '@emotion/react';
import { Router, WindowLocation, NavigateFn, globalHistory } from '@reach/router';
import { useCallback, useRef } from 'react';
import { useMount, useUpdate } from 'react-use';
import ReactGA from 'react-ga4';
import ReloadPrompt from './ReloadPrompt'

import
{
	HEADER_HEIGHT,
	CONTENT_WIDTH,
	MENU_WIDTH,
	COLOR_EXTRA_LIGHT_GRAY,
} from './utility/const';
import
{
	getClientWidth,
	isSmartphone,
} from './utility/utility';
import { Event } from './utility/event';
import { useLocalState } from './hook/use-local-state';
import { useEventSubscribe } from './hook/use-event-subscribe';
import { Detail } from './component/detail';
import { Page404 } from './component/page-404';
import { Timeline } from './component/timeline';
import { Verification } from './component/verification';
import { Picker } from './component/picker';
import { Feature } from './component/feature';
import logo from './assets/fanz-tok-logo-w.svg'
import menuIcon from './assets/menu-icon.svg'
import couponIcon from './assets/coupon-icon.svg'
// import { Test } from './component/test';

import './App.css'

type _RouteComponentProps<TParams = {}> = Partial<TParams> &
{
    path?		: string | undefined;
    default?	: boolean | undefined;
    location?	: WindowLocation | undefined;
    navigate?	: NavigateFn | undefined;
    uri?		: string | undefined;
	onScroll	: any;
	baseRef		: any;
};

const _Detail		= ( props: _RouteComponentProps ) => <Detail {...props} />;
const _Page404		= ( props: _RouteComponentProps ) => <Page404 {...props} />;
const _Verification	= ( props: _RouteComponentProps ) => <Verification {...props} />;
const _Timeline		= ( props: _RouteComponentProps ) => <Timeline {...props} />;
const _Feature		= ( props: _RouteComponentProps ) => <Feature {...props} />;
const _Picker			= ( props: _RouteComponentProps ) => <Picker {...props} />;
// const _Test			= ( props: _RouteComponentProps ) => <Test {...props} />;

ReactGA.initialize( `G-FBJSLNN9N8` );

const s = document.createElement( `script` );
s.setAttribute( `src`, `https://platform.twitter.com/widgets.js` );
document.body.appendChild( s );

window.addEventListener( `error`, ( e ) =>
{
	console.error( `[グローバルエラーキャッチ]` )
	console.error( e )
});

window.addEventListener( `unhandledrejection`, ( e ) =>
{
	console.error( `[unhandledrejection]` )
	console.error( e );
	console.error( e.reason );
});

const Style = ( params: any ) => css`
	position				: relative;
	left					: 0px;
	top						: 0px;
	width					: var( --inner-width );
	height					: calc( 100% + env( safe-area-inset-bottom ) );
	background-color		: ${COLOR_EXTRA_LIGHT_GRAY};
	overflow-x				: hidden;
	overflow-y				: hidden;

	--sidePadding			: 12px;
	@media screen and ( min-width : 650px )
	{
		--sidePadding		: 24px;
	}

	.content-base
	{
		width				: 100%;
		height				: 100%;
		max-width			: ${params.contentWidth};
		margin-left			: auto;
		margin-right		: auto;
	}
	.header-base
	{
		position			: fixed;
		top					: 0px;
		width				: 100%;
		max-width			: ${params.contentWidth};
		height				: ${HEADER_HEIGHT}px;
		filter				: drop-shadow( 0px 0px 2px rgba( 0, 0, 0, 0.1 ) );
		transform			: translateZ( 0 );
		background-color	: #FFFFFF;
		z-index				: 100;
		margin-left			: auto;
		margin-right		: auto;
		text-align			: center;

		@keyframes header-hide
		{
			from { top : -${HEADER_HEIGHT}px }
			to   { top : -${HEADER_HEIGHT}px }
		}
		@keyframes header-show
		{
			from { top : 0px; }
			to   { top : 0px; }
		}
		@keyframes header-in
		{
			from { top : -${HEADER_HEIGHT}px; animation-timing-function: ease-out; }
			to   { top : 0px;				  animation-timing-function: ease-out; }
		}
		@keyframes header-out
		{
			from { top : 0px;				  animation-timing-function: ease-out; }
			to   { top : -${HEADER_HEIGHT}px; animation-timing-function: ease-out; }
		}
		top					: -${HEADER_HEIGHT}px;
		animation			: 0.2s ${params.headerMode} forwards;
	}
	.logo-image
	{
		position			: relative;
		height				: calc( ${HEADER_HEIGHT}px - 5px - 5px );
		padding-top			: 5px;
		padding-bottom		: 5px;
	}
	.header-menu-button
	{
		position			: absolute;
		left				: 8px;
		top					: 5px;
		width				: 32px;
		height				: 32px;
		cursor				: pointer;
	}
	.header-menu-button:hover
	{
		transform			: scale( 1.1 );
	}
	.header-help-button
	{
		position			: absolute;
		right				: 10px;
		top					: 5px;
		width				: 32px;
		height				: 32px;
		cursor				: pointer;
		transform			: scale( 0.7 );
	}
	.header-help-button:hover
	{
		transform			: scale( 0.8 );
	}
`;

const App = () =>
{
	const state = useLocalState(
	{
		isShowMenu		: false,
		isResizing		: false,
		innerWidth		: 0,
		innerHeight		: 0,
		headerMode		: `header-show`,
		scrollState		: ``,
		scrollOffset	: 0,
		isFullContent	: false,
	});
	const update = useUpdate();
	const ref = useRef( null );

	const onClickMenu = useCallback( () =>
	{
		Event.publish( `PUSH_MENU_BUTTON` );
	}, [] );

	const onClickSideMenu = useCallback( () =>
	{
		Event.publish( `PUSH_SIDE_MENU_BUTTON` );
	}, [] );

	const onScroll = useCallback( ( params: any ) =>
	{
		if( state.headerMode === `header-out` )
		{
			Event.publish( `HIDE_MENU`, { force: false } );
		}
		state.headerMode = params.scrollDirection === `forward` ? `header-out` : `header-in`;
		update();
	}, [ state.headerMode, update ] );

	const changeWindowSizeEnd = useCallback( () =>
	{
		setTimeout( () =>
		{
			const isEnd = state.innerWidth === window.innerWidth && innerHeight === window.innerHeight;
			if( isEnd )
			{
				state.isResizing = false;
				Event.publish( `CHANGE_WINDOW_SIZE_END`, { width: window.innerWidth, height: window.innerHeight } );
			}
			else
			{
				state.innerWidth  = window.innerWidth;
				state.innerHeight = window.innerHeight;
				changeWindowSizeEnd();
			}
		}, 100 );
	}, [ state.innerWidth, state.innerHeight ] );

	const onResize = useCallback( () =>
	{
		if( state.isResizing === false )
		{
			state.isResizing = true;
			changeWindowSizeEnd();
		}
		document.documentElement.style.setProperty( `--inner-width` , `${window.innerWidth}px`  );
		document.documentElement.style.setProperty( `--inner-height`, `${window.innerHeight}px` );
	}, [ state.isResizing ] );

	const onWindowScroll = useCallback( () =>
	{
	    const scrollOffset = document.documentElement.scrollTop || document.body.scrollTop;
		const scrollDirection = state.scrollOffset - scrollOffset <= 0 ? `forward` : `backward`;
		if( state.scrollState === `fade` )
		{
			if( state.scrollState !== scrollDirection )
			{
				state.scrollState = ``;
			}
		}
		else if( state.scrollState === `` || state.scrollState !== scrollDirection )
		{
			state.scrollState  = scrollDirection;
			state.scrollOffset = scrollOffset;
		}
		else
		{
			if( 40 < Math.abs( state.scrollOffset - scrollOffset ) )
			{
				state.scrollState = `fade`;
				onScroll( { scrollDirection: scrollDirection } );
			}
		}
		state.scrollOffset = scrollOffset;
	}, [ state.scrollState, state.scrollOffset ] );

	const toggleContentWidth = useCallback( ( params: any ) =>
	{
		if( params !== undefined )
		{
			state.isFullContent = params.isFullContent;
		}
		else
		{
			state.isFullContent = !state.isFullContent;
		}
		update();
	}, [ state.isFullContent, update ] );

	const showHeader = useCallback( () =>
	{
		state.headerMode = `header-show`;
		update();
	}, [ state.headerMode ] );

	const showMenu = useCallback( () =>
	{
		state.isShowMenu = true;
		update();
	}, [ state.isShowMenu ] );

	useEventSubscribe(
	[
		[ `TOGGLE_CONTENT_WIDTH`, toggleContentWidth ],
		[ `SHOW_HEADER`			, showHeader		 ],
		[ `SHOW_MENU`			, showMenu			 ],
	]);

	useMount( () =>
	{
		if( window.location.pathname !== `/` )
		{
			showMenu();
		}

		onResize();
		window.addEventListener( `scroll`, onWindowScroll );
		window.addEventListener( `resize`, onResize );

		return () =>
		{
			window.removeEventListener( `resize`, onResize );
			window.removeEventListener( `scroll`, onWindowScroll );
		};
	});

	const styleParams =
	{
		headerMode		: state.headerMode,
		contentWidth	: state.isFullContent ? `var( --inner-width )` : `min( ${CONTENT_WIDTH}px, var( --inner-width ) )`,

	};
	return (
		<div id={`app-base`} css={Style( styleParams )}>
			<main id={`content-base`} className={`content-base`} ref={ref}>
				<div className={`header-base`}>
					{ state.isShowMenu &&
						<img className={`header-menu-button`} src={menuIcon} alt={`menu`} onClick={onClickMenu} />
					}
					<a href={`/`} rel={`noopener noreferrer`}>
						<img src={logo} className={`logo-image`} alt={`logo`} />
					</a>
					{ state.isShowMenu &&
						<img className={`header-help-button`} src={couponIcon} alt={`menu`} onClick={onClickSideMenu} />
					}
				</div>
				<Router>
					<_Verification	onScroll={onScroll} baseRef={ref} path={`/`} />
					<_Timeline		onScroll={onScroll} baseRef={ref} path={`/timeline`} />
					<_Detail		onScroll={onScroll} baseRef={ref} path={`/detail`} />
					<_Feature		onScroll={onScroll} baseRef={ref} path={`/feature`} />
					<_Page404		onScroll={onScroll} baseRef={ref} path={`*`} />
					<_Picker		onScroll={onScroll} baseRef={ref} path={`/picker`} />
					{/* <_Test			onScroll={onScroll} baseRef={ref} path={`/test`} /> */}
				</Router>
				<ReloadPrompt />
			</main>
		</div>
	);
};

globalHistory.listen( ( { location } ) =>
{
	ReactGA.send( { hitType: `pageview`, page: `${location.pathname}${location.search}` } );

	// 詳細から詳細の go、back の遷移で必要
	if( location.state && location.state.from === `detail` )
	{
		window.location.reload();	// なぜか必要
	}
});

export default App;
