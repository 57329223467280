import 'dayjs/locale/ja';
import { css } from '@emotion/react';
import { memo, useCallback } from 'react';
import { useMount, useUpdate } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import
{
	FANZ_TOK_AV_ACTORESS_TAG,
	COLOR_EXTRA_LIGHT_GRAY,
	COLOR_FANZTOK_MAIN,
} from '../utility/const';
import
{
	upsertTag,
} from '../utility/utility';
import { useLocalState } from '../hook/use-local-state';

dayjs.locale( `ja` );
dayjs.extend( relativeTime );

const Layout = memo( ( params: any ) =>
{
	return (
		<div css={params.style} id={params.item.id} onClick={params.onClickItem}>
			<img className={`tweet-icon`} src={params.item.icon_url}></img>
			<div className={`tweet-date`}>{dayjs( params.item.date ).format( `YYYY/MM/DD HH:mm:ss` )}</div>
			<div className={`tweet-text`}>{params.item.text}</div>
			<div className={`tweet-name`}>{params.item.screen_name}</div>
			<div className={`tweet-favo`}>いいね: {params.item.favorite_count}</div>
			<div className={`tweet-rt`}>リツイート: {params.item.retweet_count}</div>

			<div className={`tweet-tag-button-base`}>
				{params.tagButtons}
			</div>

			{ 0 < params.medias.length &&
				<div className={`tweet-media`}>
					{params.medias}
				</div>
			}
			{ 0 < params.hashes.length &&
				<div className={`tweet-hashes`}>ハッシュタグ：
					{params.hashes}
				</div>
			}
			{ 0 < params.links.length &&
				<div className={`tweet-links`}>リンク：
					{params.links}
				</div>
			}
			{ 0 < params.mentions.length &&
				<div className={`tweet-mentions`}>メンション：
					{params.mentions}
				</div>
			}

{/* 元ツイートへのリンクを配置する */}

		</div>
	);
});

const Style = ( params: any ) => css`
	position				: relative;
	left					: 0px;
	top						: 0px;
	padding-left			: 4px;
	padding-right			: 4px;
	padding-top				: 8px;
	padding-bottom			: 16px;
	margin					: 0px;
	border-width			: 1px;
	border-color			: lightgray;
	border-top-style		: solid;
	font-size				: 14px;

	.tweet-icon
	{
		position			: relative;
		width				: 32px;
		height				: auto;
		border-radius		: 100%;
	}
	.tweet-date
	{
	}
	.tweet-text
	{
	}
	.tweet-name
	{
	}
	.tweet-favo
	{
	}
	.tweet-rt
	{
	}
	.tweet-hashes
	{
	}
	.tweet-hash
	{
		padding				: 4px;
	}
	.tweet-links
	{
	}
	.tweet-link
	{
		padding				: 4px;
	}
	.tweet-mentions
	{
	}
	.tweet-mention
	{
		padding				: 4px;
	}
	.tweet-media
	{
	}
	.tweet-image
	{
		position			: relative;
		width				: 100%;
		height				: auto;
		border-radius		: 8px;
	}
	.tweet-video
	{
	}
	.tweet-tag-button-base
	{
		padding-top			: 8px;
		padding-bottom		: 8px;
	}
	.tweet-tag-button-on
	{
		padding				: 4px;
		margin-right		: 4px;
		margin-bottom		: 4px;
		background-color	: ${COLOR_EXTRA_LIGHT_GRAY};
		border-width		: 2px;
		border-style		: solid;
		border-color		: ${COLOR_FANZTOK_MAIN};
	}
	.tweet-tag-button-off
	{
		padding				: 4px;
		margin-right		: 4px;
		margin-bottom		: 4px;
		background-color	: ${COLOR_EXTRA_LIGHT_GRAY};
	}
`;

export const TweetItem = ( props: any ) =>
{
	const state = useLocalState(
	{
		hashes		: [],
		links		: [],
		mentions	: [],
		medias		: [],
		tagButtons	: [],
	});
	const update = useUpdate();

	useMount( () =>
	{
		const key = uuidv4();
		let counter = 0;
		for( const hash of props.item.hashtags )
		{
			state.hashes.push( <a key={`${key}${counter++}`} className={`tweet-hash`} target={`_blank`} rel={`noopener noreferrer`} href={`https://twitter.com/hashtag/${hash}?src=hashtag_click`}>#{hash}</a> );
		}
		for( const link of props.item.links )
		{
			state.links.push( <a key={`${key}${counter++}`} className={`tweet-link`} target={`_blank`} rel={`noopener noreferrer`} href={link}>{link}</a> );
		}
		for( const mention of props.item.mentions )
		{
			state.mentions.push( <div key={`${key}${counter++}`} className={`tweet-mention`}>@{mention}</div> );
		}
		for( let i = 0; i < props.item.media_types.length; i++ )
		{
			const type = props.item.media_types[ i ];
			const url = props.item.media_urls[ i ];
			if( type === `photo` )
			{
				state.medias.push( <img key={`${key}${counter++}`} className={`tweet-image`} src={url} alt={`${i}`} loading={`lazy`} decoding={`async`} content-visibility={`auto`} ></img> );
			}
			else if( type === `video` )
			{
				// state.medias.push( <video key={`${key}${counter++}`} className={`tweet-video`} src={url} ></video> );
				// 埋め込まれた動画の url が取れてない。かつ元の video タグでは blob に url が指定されており、おそらくリファラを見て再生可否判別をしていると思われる
				state.medias.push( <img key={`${key}${counter++}`} className={`tweet-image`} src={url} alt={`${i}`} loading={`lazy`} decoding={`async`} content-visibility={`auto`} ></img> );
			}
			else
			{
				console.log( `未対応なメディアタイプ`, type )
			}
		}
		for( const tag of FANZ_TOK_AV_ACTORESS_TAG )
		{
			const className = props.item.tags.includes( tag ) ? `tweet-tag-button-on` : `tweet-tag-button-off`;
			state.tagButtons.push( <button key={`${key}${counter++}`} className={className} data-tag={tag} onClick={params.onClickTag}>{tag}</button> );
		}
		update();
	});

	const onClickTag = useCallback( async ( e: any ) =>
	{
		await upsertTag( props.item.id, props.item.tags, e?.currentTarget?.dataset?.tag );
		const key = uuidv4();
		let counter = 0;
		state.tagButtons = [];
		for( const tag of FANZ_TOK_AV_ACTORESS_TAG )
		{
			const className = props.item.tags.includes( tag ) ? `tweet-tag-button-on` : `tweet-tag-button-off`;
			state.tagButtons.push( <button key={`${key}${counter++}`} className={className} data-tag={tag} onClick={params.onClickTag}>{tag}</button> );
		}
		update();
	}, [ update ] );

	const onClickItem = useCallback( async ( e: any ) =>
	{
		console.log( props.item.id )
	}, [] );

	const styleParams =
	{
	};
	const params =
	{
		style		: Style( styleParams ),
		item		: props.item,
		hashes		: state.hashes,
		links		: state.links,
		mentions	: state.mentions,
		medias		: state.medias,
		tagButtons	: state.tagButtons,
		onClickTag	: onClickTag,
		onClickItem	: onClickItem,
	};
	return ( <Layout {...params} /> );
};
