import { css } from '@emotion/react';
import { memo } from 'react';

import
{
	COLOR_FANZTOK_MAIN,
	COLOR_GRADATION,
	CONTENT_WIDTH,
} from '../utility/const';

import { UserIcon } from './user-icon';

const Layout = memo( ( params: any ) =>
{
	return (
		<div css={params.style}>
			<div className={`catch-base`}>
				<div className={`catch-image-base`}>
					<img className={`catch-image`} src={params.url} alt={``} loading={`lazy`} content-visibility={`auto`} />
					<h1 className={`catch-text`}>{params.caption}</h1>
					<div className={`catch-user-icons`}>
						{params.items.map( ( item: any, index: number ) => <UserIcon key={index} name={item.name} url={item.url} /> )}
					</div>
				</div>
			</div>
		</div>
	);
});

const Style = ( params: any ) => css`
	position				: relative;
	width					: 100%;
	padding					: 0px;
	margin					: 0px;

	.catch-base
	{
		padding-top			: 40px;
	}
	.catch-image-base
	{
		position			: relative;
		width				: calc( 100% - 24px );
		height				: 100%;
		background			: linear-gradient( to right, ${COLOR_FANZTOK_MAIN}, ${COLOR_GRADATION} );
		padding-left		: 12px;
		padding-right		: 12px;
		padding-top			: 12px;
		padding-bottom		: 8px;
	}
	.catch-image
	{
		position			: relative;
		width				: 100%;
		height				: 100%;
		object-fit			: cover;
		filter				: brightness( 60% ) contrast( 100% );
	}
	.catch-text
	{
		position			: absolute;
		bottom				: 50px;
		left				: -12px;
		width				: 80%;
		padding-left		: 12px;
		padding-right		: 12px;
		padding-top			: 8px;
		padding-bottom		: 8px;
		margin-left			: 16px;
		transform			: rotate( -3deg );
		font-family			: 'M PLUS 1', sans-serif;
		font-weight			: bold;
		font-size			: min( calc( var( --inner-width ) / 20 ), 30px );
		line-height			: 32px;
		background			: linear-gradient( -90deg, ${COLOR_GRADATION}, ${COLOR_FANZTOK_MAIN} );
		color				: white;
	}
	.catch-user-icons
	{
		position			: absolute;
		top					: 30px;
		left				: 24px;
		display				: flex;
		row-gap				: 4px;
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			flex-direction	: row;
		}
		@media screen and ( min-width : 650px )
		{
			flex-direction	: column;
		}
	}
`;

export const CatchImage = memo( ( props: any ) =>
{
	const styleParams =
	{
	};
	const params =
	{
		style	: Style( styleParams ),
		url		: props.url,
		caption	: props.caption,
		items	: props.items,
	};
	return ( <Layout {...params} /> );
});
