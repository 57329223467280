import { css } from '@emotion/react';
import { memo, useCallback } from 'react';
import { useUpdate } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

import
{
	HEADER_HEIGHT,
	TWEET_TIMELINE_ITEM_COUNT,
} from '../utility/const';
import
{
	searchTweets,
	getClientHeight,
} from '../utility/utility';
import { useLocalState } from '../hook/use-local-state';
import { useEventSubscribe } from '../hook/use-event-subscribe';
import { ScrollList } from './scroll-list';
import { TweetItem } from './tweet-item';

const Layout = memo( ( params: any ) =>
{
	return (
		<div css={params.style}>
			<ScrollList id={`tweet-timeline`} height={params.height} threshold={0} startPos={`top`} onLoad={params.onLoad} onRenderItem={params.onRenderItem} onNoScrollItem={params.onNoScrollItem} />
		</div>
	);
});

const Style = ( params: any ) => css`
	position	: relative;
	width		: calc( calc( 100% - 8px ) / 4 );
	height		: 100%;
`;

export const TweetTimeline = memo( ( props: any ) =>
{
	const state = useLocalState(
	{
		height	: getClientHeight() - HEADER_HEIGHT,
		start	: 0,
		items	: [],
		key		: uuidv4(),
		counter	: 0,
	});
	const update = useUpdate();

	const onLoad = async ( direction: string, useCache: boolean ) =>
	{
		if( direction === `prev` )
		{
			return { mode: `none`, data: [] };
		}
		let items = await searchTweets( props.screenName, state.start, state.start + TWEET_TIMELINE_ITEM_COUNT - 1 );
		if( items.length === 0 )
		{
			return { mode: `none`, data: [] };
		}
		state.start += TWEET_TIMELINE_ITEM_COUNT;
		items = items.filter( ( item: any ) => 0 < item.media_types.length );
// fanztok タグ設定ありだけにフィルタする機能もほしい
		state.items = [ ...state.items, ...items ];
		return { mode: `override`, data: state.items };
	};

	const onRenderItem = ( index: number, item: any, isModify: boolean, id: number ) =>
	{
		return <TweetItem key={`${state.key}${state.counter++}`} item={item} />;
	};

	const onNoScrollItem = useCallback( ( params: any ) =>
	{
		return null;
	}, [] );

	const changeWindowSizeEnd = useCallback( ( params: any ) =>
	{
		state.height = params.height - HEADER_HEIGHT;
		update();
	}, [ state.height, update ] );

	useEventSubscribe(
	[
		[ `CHANGE_WINDOW_SIZE_END`, changeWindowSizeEnd ],
	]);

	const styleParams =
	{
	};
	const params =
	{
		style			: Style( styleParams ),
		height			: state.height,
		onLoad			: onLoad,
		onRenderItem	: onRenderItem,
		onNoScrollItem	: onNoScrollItem,
	};
	return ( <Layout {...params} /> );
});
