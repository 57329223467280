import { css } from '@emotion/react';
import { memo, useCallback } from 'react';
import { navigate } from '@reach/router';
import { useMount } from 'react-use';

import
{
	COLOR_FANZTOK_MAIN,
	COLOR_ULTRA_LIGHT_GRAY,
} from '../utility/const';
import
{
	removeSessionStorage,
} from '../utility/utility';

const Layout = memo( ( params: any ) =>
{
	return (
		<div css={params.style}>
			<div className={`info-base`}>
				<div className={`title`}>年齢認証</div>
				<div className={`cation`}>あなたは18歳以上ですか？</div>
				<div className={`description`}>これから先は、アダルト情報を扱うアダルトサイトとなります。<br />18歳未満の方はアクセスを固くお断りします。</div>
			</div>
			<div className={`button-base`}>
				<button className={`button-ng`} onClick={params.onClickNG}>いいえ</button>
				<button className={`button-ok`} onClick={params.onClickOK}>はい</button>
			</div>
		</div>
	);
});

const Style = ( params: any ) => css`
	position				: relative;
	width					: 100%;
	height					: 100vh;
	background-color		: ${COLOR_ULTRA_LIGHT_GRAY};

	.info-base
	{
		text-align			: center;
		padding-left		: 40px;
		padding-right		: 40px;
	}
	.title
	{
		padding-top			: 120px;
		font-size			: 30px;
	}
	.cation
	{
		padding-top			: 60px;
		font-size			: 24px;
		font-weight			: bold;
	}
	.description
	{
		padding-top			: 60px;
		font-size			: 14px;
		color				: gray;
	}
	.button-base
	{
		padding-top			: 40px;
		padding-bottom		: 40px;
		text-align			: center;
		background-color	: ${COLOR_ULTRA_LIGHT_GRAY};
	}
	.button-ng
	{
		width				: 150px;
		margin-right		: 12px;
		font-weight			: bold;
		transform			: scale( 1.0 );
		transition			: all 0.1s 0s ease;
	}
	.button-ng:hover
	{
		transform			: scale( 1.03 );
	}
	.button-ok
	{
		width				: 150px;
		margin-left			: 12px;
		font-weight			: bold;
		background-color	: ${COLOR_FANZTOK_MAIN};
		color				: white;
		transform			: scale( 1.0 );
		transition			: all 0.1s 0s ease;
	}
	.button-ok:hover
	{
		transform			: scale( 1.03 );
	}
`;

export const Verification = memo( ( props: any ) =>
{
	const onClickOK = useCallback( () =>
	{
		localStorage.setItem( `age-check`, `true` );
		navigate( `/timeline`, { state: {}, replace: false } );
	}, [] );

	const onClickNG = useCallback( () =>
	{
		window.location.href = `https://google.co.jp`;
	}, [] );

	useMount( async () =>
	{
		removeSessionStorage( `categoryId` );
	});

	const ageCheck = localStorage.getItem( `age-check` );
	if( ageCheck === `true` )
	{
		// navigate( `/timeline`, { state: {}, replace: false } );
	}

	const styleParams =
	{
	};
	const params =
	{
		style		: Style( styleParams ),
		onClickOK	: onClickOK,
		onClickNG	: onClickNG,
	};
	return ( <Layout {...params} /> );
});
