import { css } from '@emotion/react';
import { memo } from 'react';

import
{
	COLOR_FANZTOK_MAIN,
	COLOR_GRADATION,
} from '../utility/const';

const Layout = memo( ( params: any ) =>
{
	return (
		<div css={params.style}>
			<div className={`user-icon-base`}>
				<span className={`user-icon-name`}>{params.name}</span>
				<img className={`user-icon-image`} src={params.url} alt={``} loading={`lazy`} content-visibility={`auto`} />
			</div>
		</div>
	);
});

const Style = ( params: any ) => css`
	position				: relative;
	width					: 100%;
	padding					: 0px;
	margin					: 0px;

	.user-icon-base
	{
	}
	.user-icon-image
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			margin-right	: 16px;
			max-width		: min( calc( var( --inner-width ) / 12 ), 64px );
			max-height		: min( calc( var( --inner-width ) / 12 ), 64px );
		}
		@media screen and ( min-width : 650px )
		{
			margin-right	: 0px;
			max-width		: min( calc( var( --inner-width ) / 16 ), 64px );
			max-height		: min( calc( var( --inner-width ) / 16 ), 64px );
		}
		position			: relative;
		top					: min( calc( var( --inner-width ) / 10 ), -5px );
		object-fit			: cover;
		border-radius		: 100%;
		border-width		: 2px;
		border-color		: white;
		border-style		: solid;
	}
	.user-icon-name
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			visibility		: hidden;
			width			: 0px;
			height			: 0px;
			font-size		: 0px;
			padding-left	: 0px;
			padding-right	: 0px;
			padding-top		: 0px;
			padding-bottom	: 0px;
		}
		@media screen and ( min-width : 650px )
		{
			visibility		: visible;
			width			: 100%;
			height			: 100%;
			font-size		: 24px;
			padding-left	: 8px;
			padding-right	: 16px;
			padding-top		: 8px;
			padding-bottom	: 8px;
		}
		position			: relative;
		left				: -12px;
		top					: min( calc( var( --inner-width ) / -40 ), -10px );
		color				: white;
		background			: linear-gradient( -90deg, ${COLOR_GRADATION}, ${COLOR_FANZTOK_MAIN} );
		font-family			: 'M PLUS 1', sans-serif;
		font-weight			: bold;
	}
`;

export const UserIcon = memo( ( props: any ) =>
{
	const styleParams =
	{
	};
	const params =
	{
		style	: Style( styleParams ),
		url		: props.url,
		name	: props.name,
	};
	return ( <Layout {...params} /> );
});
