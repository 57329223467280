import { useCallback, memo } from 'react';
import { css } from '@emotion/react';
import { useUpdate } from 'react-use';

import
{
	COLOR_FANZTOK_MAIN,
	COLOR_GRADATION,
} from '../utility/const';
import { useLocalState } from '../hook/use-local-state';
import { useEventSubscribe } from '../hook/use-event-subscribe';

const Layout = ( params: any ) =>
{
	return (
		<div css={params.style}>
			<div className={`dialog-base`}>
				<div className={`dialog-info`}>
					<h1 className={`dialog-title`}>{params.params.title}</h1>
					<img className={`dialog-image`} src={params.params.imageUrl} alt={``} loading={`lazy`} content-visibility={`auto`} />
				</div>
				<div className={`dialog-button`}>
					<button className={`button-purchase`} onClick={params.onClickPurchase}>DVDを購入する</button>
					<button className={`button-download`} onClick={params.onClickDownload}>動画をダウンロード</button>
				</div>
			</div>
		</div>
	);
};

const Style = ( params: any ) => css`
	position		 		: fixed;
	left			 		: 0px;
	top				 		: 0px;
	width			 		: 100vw;
	height			 		: 100vh;
	z-index			 		: 100;
	background-color 		: #00000000;
	animation	  	 		: 0.125s fadein-color-cover forwards;
	@keyframes fadein-color-cover
	{
		from { background-color : #00000000; }
		to   { background-color : #00000040; }
	}
	display			 		: flex;
	justify-content	 		: center;
	align-items		 		: center;

	.dialog-base
	{
		display				: flex;
		flex-direction		: column;
		justify-content	 	: center;
		height				: 100%;
		border-radius		: 12px;
	}

	.dialog-info
	{
		display				: inline-block;
		background-color	: white;
	}
	.dialog-title
	{
		padding-top			: 16px;
		padding-bottom		: 16px;
		padding-left		: 16px;
		line-height			: 32px;
		font-size			: 20px;
		font-weight			: bold;
		color				: white;
		background			: linear-gradient( to right, ${COLOR_FANZTOK_MAIN}, ${COLOR_GRADATION} );
	}
	.dialog-image
	{
		position			: relative;
		width				: 100%;
		object-fit			: cover;
	}

	.dialog-button
	{
		display				: inline-block;
		padding-top			: 16px;
		padding-bottom		: 24px;
		text-align			: center;
		background-color	: white;
	}
	.button-purchase
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-left	: 16px;
			padding-right	: 16px;
			margin-right	: 8px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-left	: 48px;
			padding-right	: 48px;
			margin-right	: 16px;
		}
		transform			: scale( 1.0 );
		transition			: all 0.1s 0s ease;
		background-color	: ${COLOR_FANZTOK_MAIN};
		color				: white;
		font-weight			: bold;
	}
	.button-purchase:hover
	{
		transform			: scale( 1.03 );
	}
	.button-download
	{
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			padding-left	: 16px;
			padding-right	: 16px;
			margin-left		: 8px;
		}
		@media screen and ( min-width : 650px )
		{
			padding-left	: 48px;
			padding-right	: 48px;
			margin-left		: 16px;
		}
		transform			: scale( 1.0 );
		transition			: all 0.1s 0s ease;
		background-color	: ${COLOR_GRADATION};
		color				: white;
		font-weight			: bold;
	}
	.button-download:hover
	{
		transform			: scale( 1.03 );
	}
`;

export const Dialog = memo( ( props: any ) =>
{
	const state = useLocalState(
	{
		visible		: false,
		params		: {},
	});
	const update = useUpdate();

	const onClickPurchase = useCallback( () =>
	{
		window.open( state.params.contentUrlP );
	}, [] );

	const onClickDownload = useCallback( () =>
	{
		window.open( state.params.contentUrlD );
	}, [] );

	const onClickClose = useCallback( ( e: any ) =>
	{
		state.visible = false;
		update();
	}, [ props.id, state, update ] );

	const showDialog = useCallback( ( params: any ) =>
	{
		state.visible = true;
		state.params  = params;
		update();
	}, [ state.visible, state.params, update ] );

	useEventSubscribe(
	[
		[ `SHOW_DIALOG`, showDialog ],
	]);

	if( state.visible === false )
	{
		return null;
	}

	const styleParams =
	{
	};
	const params =
	{
		style			: Style( styleParams ),
		params			: state.params,
		onClickClose	: onClickClose,
		onClickPurchase	: onClickPurchase,
		onClickDownload	: onClickDownload,
	};
	return ( <Layout {...params} /> );
});
