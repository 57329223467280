import { css } from '@emotion/react';
import { memo, useCallback } from 'react';
import { Tween24, Ease24 } from "tween24";
import { useUpdate, useMount } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { isAndroid } from 'react-device-detect';

import
{
	HOME_TIMELINE_ITEM_COUNT,
	CONTENT_WIDTH,
	MENU_WIDTH,
} from '../utility/const';
import
{
	getClientHeight,
	getClientWidth,
	getFanzaContentInfo,
	getSessionStorage,
	getTimelineList,
	getUrlParams,
	isSmartphone,
	randomRangeInteger,
	setSessionStorage,
	sleep,
	supabaseGetFanzaGoodMovies,
	toInteger,
} from '../utility/utility';
import { Event } from '../utility/event';
import { useLocalState } from '../hook/use-local-state';
import { useEventSubscribe } from '../hook/use-event-subscribe';
import { ScrollList } from './scroll-list';
import { HomeMovieItem } from './home-movie-item';
import { CategoryMenu } from './category-menu';
import { SideMenu } from './side-menu';

Tween24.setDefaultEasing( Ease24._6_ExpoOut );

const Layout = memo( ( params: any ) =>
{
	return (
		<div css={params.style}>
			<ScrollList
				id={`home-timeline`}
				height={params.height}
				threshold={0}
				startPos={`top`}
				useSwipe={true}
				onLoad={params.onLoad}
				onRenderItem={params.onRenderItem}
				onSwipe={params.onSwipe}
				onNoScrollItem={params.onNoScrollItem}
				onRendered={params.onRendered}
			/>
			<CategoryMenu />
			<SideMenu />
		</div>
	);
});

const Style = ( params: any ) => css`
	position	: relative;
	width		: 100%;
	height		: ${params.height}px;
`;

export const Timeline = memo( ( props: any ) =>
{
	const state = useLocalState(
	{
		init	: false,
		height	: getClientHeight(),
		startId	: 0,
		items	: [],
		index	: 0,
		key		: uuidv4(),
		counter	: 0,
		isWait	: false,
	});
	const update = useUpdate();

	const flipItem = useCallback( async ( direction: string ) =>
	{
		const target = document.getElementById( `scroll-inner-home-timeline` );
		const offset   = state.height + 1;
		state.isWait   = true;
		const duration = isAndroid ? 0.4 : 0.3;
		Tween24.tween( target, duration ).y$( direction === `backward` ? offset : -offset ).onComplete( () => { state.isWait = false; } ).willChange().play();
		Event.publish( `HIDE_MENU`, { force: false } );
	}, [] );

	const onLoad = async ( direction: string, useCache: boolean ) =>
	{
		const isFlip = state.init;
		if( state.init === false )
		{
			state.init	  = true;
			state.startId = getUrlParams( `id` ) === undefined ? randomRangeInteger( 0, 2200 ) : 0;
			state.items	  = [];
			Event.publish( `SHOW_HEADER` );
			Event.publish( `SHOW_MENU`, {}, true );
			Event.publish( `TOGGLE_CONTENT_WIDTH`, { isFullContent: true }, true );
			await sleep( 200 );
			Event.publish( `ENABLE_SCROLL_LIST`, { enable: false } );
		}

		if( direction === `prev` )
		{
			return { mode: `none`, data: [] };
		}
		const id = getUrlParams( `id` );

// カテゴリ指定でも state.startId をランダムで指定する

		Event.publish( `ENABLE_SCROLL_LIST`, { enable: true } );

		const categoryId = id === undefined ? 0 : toInteger( id );

		const items = await supabaseGetFanzaGoodMovies( 0, categoryId, state.startId, HOME_TIMELINE_ITEM_COUNT );
		if( items.length === 0 )
		{
			return { mode: `none`, data: [] };
		}

// 		const list = await getTimelineList( `fanza`, categoryId, state.startId, HOME_TIMELINE_ITEM_COUNT );
// 		const contentHash = {};
// 		const requests	  = [];
// 		for( const item of list )
// 		{
// 			contentHash[ item.content_id ] = item;
// 			requests.push( getFanzaContentInfo( item.content_id, item.description ) );
// 		}

// 		const results = await Promise.all( requests );
// 		const items	  = [];
// 		for( const item of results )
// 		{
// 			items.push(
// 			{
// 				id							: item.content_id,
// 				title						: item.title,
// 				description					: contentHash[ item.content_id ].description,
// 				package_image_detail_url	: item.imageURL.large,
// 				sample_image_urls			: item.sampleImageURL.sample_l.image,
// 				sample_moviel_url			: item.sampleMovieURL.size_720_480,
// 			});
// console.log( `MOVIE`, item.sampleMovieURL.size_720_480 )
// // この url は iframe で埋め込まれなければ再生できないのかもしれない

// 		}
		state.startId += HOME_TIMELINE_ITEM_COUNT;
		state.items = [ ...state.items, ...items ];

		if( isFlip )
		{
			flipItem( `forward` );
		}
		setSessionStorage( `categoryId`, categoryId );
		return { mode: `override`, data: state.items };
	};

	const onNoScrollItem = useCallback( ( params: any ) =>
	{
		return null;
	}, [] );

	const onSwipe = useCallback( async ( direction: string ) =>
	{
		if( state.isWait )
		{
			return;
		}
		if( document.fullscreenElement )
		{
			return;
		}
		if( direction === `backward` )
		{
			if( state.index === 0 )
			{
				return;
			}
			state.index--;
		}
		else // forward
		{
			state.index++;
			if( state.items.length <= state.index )
			{
				Event.publish( `LOAD_NEXT_SCROLL_LIST` );
				return;
			}
		}
		flipItem( direction );
	}, [ state.isWait, state.height, update, flipItem ] );

	const onRenderItem = useCallback( ( index: number, item: any, isModify: boolean, id: number ) =>
	{
		return <HomeMovieItem key={`${state.key}${state.counter++}`} item={item} onSwipe={onSwipe} />;
	}, [ state.key, state.counter ] );

	const onRendered = useCallback( async ( scrollRef: any ) =>
	{
		Event.publish( `ENABLE_SCROLL_LIST`, { enable: false } );
	}, [] );

	const changeWindowSizeEnd = useCallback( ( params: any ) =>
	{
		state.height = params.height;
		update();
	}, [ state.height, update ] );

	useEventSubscribe(
	[
		[ `CHANGE_WINDOW_SIZE_END`, changeWindowSizeEnd ],
	]);

	useMount( async () =>
	{
		if( isSmartphone() === false )
		{
			if( CONTENT_WIDTH + MENU_WIDTH * 2 <= getClientWidth() )
			{
				setTimeout( () =>
				{
					Event.publish( `PUSH_MENU_BUTTON` );
					Event.publish( `PUSH_SIDE_MENU_BUTTON` );
				}, 5000 );
			}
		}
	});

	const styleParams =
	{
		height			: state.height,
	};
	const params =
	{
		style			: Style( styleParams ),
		height			: state.height,
		onLoad			: onLoad,
		onRenderItem	: onRenderItem,
		onNoScrollItem	: onNoScrollItem,
		onSwipe			: onSwipe,
		onRendered		: onRendered,
	};
	return ( <Layout {...params} /> );
});
