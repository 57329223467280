import { css } from '@emotion/react';
import { memo, useCallback } from 'react';
import { navigate } from '@reach/router';
import { Tween24, Ease24 } from "tween24";
import { useMount, useUpdate } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

import
{
	HEADER_HEIGHT,
	CONTENT_WIDTH,
	COLOR_FANZTOK_MAIN,
	MENU_WIDTH,
} from '../utility/const';
import
{
	getCategoryList,
	getClientWidth,
	getSessionStorage,
	getUrlParams,
	isSmartphone,
	randomRangeInteger,
	toInteger,
} from '../utility/utility';
import { useLocalState } from '../hook/use-local-state';
import { useEventSubscribe } from '../hook/use-event-subscribe';

Tween24.setDefaultEasing( Ease24._6_ExpoOut );

const Layout = memo( ( params: any ) =>
{
	return (
		<div css={params.style} id={`category-menu`}>
			<div className={`category-menu-base`}>
				{params.items}
			</div>
		</div>
	);
});

const Style = ( params: any ) => css`
	position				: fixed;
	@media screen and ( max-width : calc( 650px - 1px ) )
	{
		/* left				: -100%; */
		left				: 0px;
	}
	@media screen and ( min-width : 650px )
	{
		left				: 0px;
	}
	top						: 0px;
	width					: ${params.width}px;
	height					: 100vh;
	background-color		: white;
	scrollbar-width			: none;

	.category-menu-base
	{
		position			: relative;
		left				: 0px;
		top					: 0px;
		width				: calc( ${params.width}px - 32px );
		overflow-x			: hidden;
		overflow-y			: auto;
		padding-left		: 0px;
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			margin			: 8px;
			padding-top		: 30px;
			padding-bottom	: ${HEADER_HEIGHT}px;
			height			: calc( 100vh - ${HEADER_HEIGHT}px - 8px - 8px );
		}
		@media screen and ( min-width : 650px )
		{
			margin			: 16px;
			padding-top		: 30px;
			padding-bottom	: ${HEADER_HEIGHT}px;
			height			: calc( 100vh - ${HEADER_HEIGHT}px - 16px - 16px );
		}
	}
	.category-menu-base::-webkit-scrollbar
	{
		display				: none;
	}
	.category-menu-category-item
	{
		position			: relative;
		left				: 0px;
		top					: 0px;
		font-size			: 14px;
		font-weight			: bold;
		line-height			: 40px;
		border-bottom		: 1px dotted hsla( 0, 0%, 0%, 0.5 );
		margin-bottom		: 12px;
		color				: ${COLOR_FANZTOK_MAIN};
	}
	.category-menu-category-item:not(:first-of-type)
	{
		padding-top			: 12px;
	}
	.category-menu-item
	{
		position			: relative;
		left				: 0px;
		top					: 0px;
		font-size			: 12px;
	}
	.category-menu-item-link
	{
		color				: #000000;
		text-decoration		: none;
	};
	.category-menu-item-link:hover
	{
		color				: ${COLOR_FANZTOK_MAIN};
		text-decoration		: underline;
	};
	.category-menu-item-link-select
	{
		color				: #000000;
		text-decoration		: none;
		font-weight			: bold;
	};
	.category-menu-item-link-select:hover
	{
		color				: ${COLOR_FANZTOK_MAIN};
		text-decoration		: underline;
		font-weight			: bold;
	};

`;

export const CategoryMenu = ( props: any ) =>
{
	const state = useLocalState(
	{
		isInit	: false,
		isShow	: false,
		width	: 0,
		items	: [],
	});
	const update = useUpdate();

	const updateMenuWidth = useCallback( () =>
	{
		if( isSmartphone() )
		{
			state.width = getClientWidth();
		}
		else
		{
			state.width = MENU_WIDTH;
		}
		update();
	}, [ state.width, state.isShow, update ] );

	const initMenu = useCallback( () =>
	{
		state.isInit = true;
		const target = document.getElementById( `category-menu` );
		if( isSmartphone() )
		{
			state.isShow = false;
			Tween24.tween( target, 0.0, Ease24._BackOut ).x( -getClientWidth() ).willChange().skip();
		}
		else
		{
			state.isShow = false;
			Tween24.tween( target, 0.0, Ease24._BackOut ).x( -MENU_WIDTH ).willChange().skip();
		}
		updateMenuWidth();
	}, [ state.isInit, state.isShow, state.width, update ] );

	const openMenu = useCallback( () =>
	{
		const target = document.getElementById( `category-menu` );
		Tween24.tween( target, 0.3, Ease24._6_ExpoOut ).x( 0 ).willChange().play();
		state.isShow = true;
	}, [ state.isShow ] );

	const closeMenu = useCallback( ( params: any ) =>
	{
		if( params.force === false )
		{
			if( 1050 < getClientWidth() )
			{
				return;
			}
		}
		const target = document.getElementById( `category-menu` );
		Tween24.tween( target, 0.3, Ease24._6_ExpoOut ).x$( -state.width ).willChange().play();
		state.isShow = false;
	}, [ state.isShow, state.width ] );

	const onClickDetail = useCallback( ( e: any ) =>
	{
		navigate( `/detail?id=${props.item.id}`, { state: { id: props.item.id }, replace: false } );
	}, [] );

	const changeWindowSizeEnd = useCallback( ( params: any ) =>
	{
		if( state.isInit )
		{
			updateMenuWidth();
		}
	}, [ updateMenuWidth ] );

	const pushMenuButton = useCallback( () =>
	{
		if( state.isShow )
		{
			closeMenu( { force: true } );
		}
		else
		{
			openMenu();
		}
	}, [ state.isShow, openMenu, closeMenu ] );

	useEventSubscribe(
	[
		[ `CHANGE_WINDOW_SIZE_END`	, changeWindowSizeEnd	],
		[ `PUSH_MENU_BUTTON`		, pushMenuButton		],
		[ `OPEN_MENU`				, openMenu				],
		[ `CLOSE_MENU`				, closeMenu				],
	]);

	useMount( async () =>
	{
		const key = uuidv4();
		let counter = 0;
		state.items	= [];
		const actoressList	= await getCategoryList( `actoress`, randomRangeInteger( 0, 120 ) );		// max=12061
		// const actoressList	= await getCategoryList( `actress` );
		const seriesList	= await getCategoryList( `series`, randomRangeInteger( 0, 110 ) );			// max=11048
		const genreList		= await getCategoryList( `genre`, randomRangeInteger( 0, 25 ) );			// max=257
		const labelList		= await getCategoryList( `label`, randomRangeInteger( 0, 130 ) );			// max=1307
		const makerList		= await getCategoryList( `maker`, randomRangeInteger( 0, 50 ) );			// max=534
		const directorList	= await getCategoryList( `director`, randomRangeInteger( 0, 170 ) );		// max=1744
		const categoryId	= getSessionStorage( `categoryId` ) ? toInteger( getSessionStorage( `categoryId` ) ) : 0;
		state.items.push( <div key={`${key}${counter++}`} className={`category-menu-category-item`}>女優で絞り込む</div> );
		for( const item of actoressList )
		{
			state.items.push(
				<div key={`${key}${counter++}`} className={`category-menu-item`}>
					<li className={`detail-footer-item`}>
						<a className={item.id === categoryId ? `category-menu-item-link-select` : `category-menu-item-link`} href={`./timeline?id=${item.id}`} rel={`noopener noreferrer`}>{item.name}（{item.item_count}）</a>
						{/* <a className={item.id === categoryId ? `category-menu-item-link-select` : `category-menu-item-link`} href={`./timeline?id=${item.id}`} rel={`noopener noreferrer`}>{item.name}（{item.count}）</a> */}
					</li>
				</div>
			);
		}

		state.items.push( <div key={`${key}${counter++}`} className={`category-menu-category-item`}>ジャンルで絞り込む</div> );
		for( const item of genreList )
		{
			state.items.push(
				<div key={`${key}${counter++}`} className={`category-menu-item`}>
					<li className={`detail-footer-item`}>
						<a className={item.id === categoryId ? `category-menu-item-link-select` : `category-menu-item-link`} href={`./timeline?id=${item.id}`} rel={`noopener noreferrer`}>{item.name}（{item.item_count}）</a>
						{/* <a className={item.id === categoryId ? `category-menu-item-link-select` : `category-menu-item-link`} href={`./timeline?id=${item.id}`} rel={`noopener noreferrer`}>{item.name}（{item.count}）</a> */}
					</li>
				</div>
			);
		}

		state.items.push( <div key={`${key}${counter++}`} className={`category-menu-category-item`}>シリーズで絞り込む</div> );
		for( const item of seriesList )
		{
			state.items.push(
				<div key={`${key}${counter++}`} className={`category-menu-item`}>
					<li className={`detail-footer-item`}>
						<a className={item.id === categoryId ? `category-menu-item-link-select` : `category-menu-item-link`} href={`./timeline?id=${item.id}`} rel={`noopener noreferrer`}>{item.name}（{item.item_count}）</a>
						{/* <a className={item.id === categoryId ? `category-menu-item-link-select` : `category-menu-item-link`} href={`./timeline?id=${item.id}`} rel={`noopener noreferrer`}>{item.name}（{item.count}）</a> */}
					</li>
				</div>
			);
		}

		state.items.push( <div key={`${key}${counter++}`} className={`category-menu-category-item`}>レーベルで絞り込む</div> );
		for( const item of labelList )
		{
			state.items.push(
				<div key={`${key}${counter++}`} className={`category-menu-item`}>
					<li className={`detail-footer-item`}>
						<a className={item.id === categoryId ? `category-menu-item-link-select` : `category-menu-item-link`} href={`./timeline?id=${item.id}`} rel={`noopener noreferrer`}>{item.name}（{item.item_count}）</a>
						{/* <a className={item.id === categoryId ? `category-menu-item-link-select` : `category-menu-item-link`} href={`./timeline?id=${item.id}`} rel={`noopener noreferrer`}>{item.name}（{item.count}）</a> */}
					</li>
				</div>
			);
		}

		state.items.push( <div key={`${key}${counter++}`} className={`category-menu-category-item`}>メーカーで絞り込む</div> );
		for( const item of makerList )
		{
			state.items.push(
				<div key={`${key}${counter++}`} className={`category-menu-item`}>
					<li className={`detail-footer-item`}>
						{/* <a className={item.id === categoryId ? `category-menu-item-link-select` : `category-menu-item-link`} href={`./timeline?id=${item.id}`} rel={`noopener noreferrer`}>{item.name}（{item.count}）</a> */}
						<a className={item.id === categoryId ? `category-menu-item-link-select` : `category-menu-item-link`} href={`./timeline?id=${item.id}`} rel={`noopener noreferrer`}>{item.name}（{item.item_count}）</a>
					</li>
				</div>
			);
		}

		state.items.push( <div key={`${key}${counter++}`} className={`category-menu-category-item`}>監督で絞り込む</div> );
		for( const item of directorList )
		{
			state.items.push(
				<div key={`${key}${counter++}`} className={`category-menu-item`}>
					<li className={`detail-footer-item`}>
						<a className={item.id === categoryId ? `category-menu-item-link-select` : `category-menu-item-link`} href={`./timeline?id=${item.id}`} rel={`noopener noreferrer`}>{item.name}（{item.item_count}）</a>
						{/* <a className={item.id === categoryId ? `category-menu-item-link-select` : `category-menu-item-link`} href={`./timeline?id=${item.id}`} rel={`noopener noreferrer`}>{item.name}（{item.count}）</a> */}
					</li>
				</div>
			);
		}

		initMenu();
	});

	const styleParams =
	{
		width			: state.width,
	};
	const params =
	{
		style			: Style( styleParams ),
		items			: state.items,
		onClickDetail	: onClickDetail,
	};
	return ( <Layout {...params} /> );
};
