import { css } from '@emotion/react';
import { InView } from 'react-intersection-observer';
import { isChrome } from 'react-device-detect';
import { navigate } from '@reach/router';
import { useMount, useUpdate } from 'react-use';
import { useSwipeable } from "react-swipeable";
import { memo, useRef, useCallback, useState } from 'react';
import Viewer from 'react-viewer';

import
{
	HEADER_HEIGHT,
	CONTENT_WIDTH,
	COLOR_FANZTOK_MAIN,
	COLOR_GRADATION,
	COLOR_EXTRA_LIGHT_GRAY,
	COLOR_ULTRA_LIGHT_GRAY,
} from '../utility/const';
import
{
	getClientHeight,
	getClientWidth,
	getUniqueKey,
	isSmartphone,
	randomRangeInteger,
	sleep,
	toInteger,
} from '../utility/utility';
import { useLocalState } from '../hook/use-local-state';
import { useEventSubscribe } from '../hook/use-event-subscribe';

let attached: any = {};

const Layout = memo( ( params: any ) =>
{
	const [ isView, setIsView ] = useState< boolean >( false );
	const ref  = useRef< any >( null );
	const item = params.item;

	if( item.id in attached === false )
	{
		if( ref.current && params.sampleMovielUrl !== `` )
		{
			attached[ item.id ] = true;
console.log( `登録`, item.id, item.title, params.sampleMovielUrl )
			ref.current.addEventListener( `progress`, ( e: any ) =>
			{
				console.log( `[${new Date()}] ${item.id} progress` )
// progress なのにローディングサインが表示されない場合がある
// waiting にならないタイミングがある？
			});
			ref.current.addEventListener( `suspend`, ( e: any ) =>
			{
				console.log( `[${new Date()}] ${item.id} suspend` )
			});
			ref.current.addEventListener( `pause`, ( e: any ) =>
			{
				console.log( `[${new Date()}] ${item.id} pause` )
			});

// pause, suspend になったが再生可能なら再生させたい

			// ref.current.addEventListener( `loadedmetadata`, ( e: any ) =>
			// {
			// 	console.log( `[${new Date()}] ${item.id} loadedmetadata` )
			// });
			// ref.current.addEventListener( `loadeddata`, ( e: any ) =>
			// {
			// 	console.log( `[${new Date()}] ${item.id} loadeddata` )
			// });
			ref.current.addEventListener( `canplay`, ( e: any ) =>
			{
				console.log( `[${new Date()}] ${item.id} canplay` )
			});
			ref.current.addEventListener( `emptied`, ( e: any ) =>
			{
				console.log( `[${new Date()}] ${item.id} emptied` )
			});
			ref.current.addEventListener( `error`, ( e: any ) =>
			{
				console.log( `[${new Date()}] ${item.id} error` )
			});
			// ref.current.addEventListener( `canplaythrough`, ( e: any ) =>
			// {
			// 	console.log( `[${new Date()}] ${item.id} canplaythrough` )
			// });
			ref.current.addEventListener( `play`, ( e: any ) =>
			{
				console.log( `[${new Date()}] ${item.id} play` )
			});
			ref.current.addEventListener( `playing`, ( e: any ) =>
			{
				console.log( `[${new Date()}] ${item.id} playing` )
			});
			ref.current.addEventListener( `ended`, ( e: any ) =>
			{
				console.log( `[${new Date()}] ${item.id} ended` )
			});
			ref.current.addEventListener( `waiting`, ( e: any ) =>
			{
				console.log( `[${new Date()}] ${item.id} waiting` )
			});
		}
	}

	const onObserverChanged = useCallback( async ( inView: boolean ) =>
	{
		if( isView && inView === false )
		{
			setIsView( inView );
		}
		else if( isView === false && inView )
		{
			setIsView( inView );
		}
		if( ref.current === null )
		{
			return;
		}
		try
		{
			if( inView )
			{
				if( document.fullscreenElement )
				{
					return;
				}
				await ref.current.play();
				ref.current.currentTime = randomRangeInteger( 5, 30 );
				if( isSmartphone() === false )
				{
					await sleep( 1000 );
					if( ref.current )
					{
						ref.current.muted = false;
					}
				}
			}
			else
			{
				ref.current.muted = true;
				ref.current.pause();
			}
		}
		catch( e )
		{
console.log( `動画再生エラー: [${params.sampleMovielUrl}]`, item.id, e )
// 画面内にあれば再生リトライする
		}
	}, [] );

	// const style1: { [ key: string ]: string } =
	// {
	// 	position	: `relative`,
	// 	paddingTop	: `0px`,
	// 	width		: params.imageWidth,
	// 	height		: params.imageHeight,
	// 	objectFit	: `cover`,
	// 	aspectRatio	: `1.7`,
	// };

	// const style2: { [ key: string ]: string } =
	// {
	// 	position	: `absolute`,
	// 	top			: `0px`,
	// 	left		: `0px`,
	// 	width		: params.imageWidth,
	// 	height		: params.imageHeight,
	// 	objectFit	: `cover`,
	// 	aspectRatio	: `1.7`,
	// };

	return (
		<div css={params.style} id={item.id} {...params.onSwipe}>
			<div className={`movie-item-base`}>
				<div className={`movie-item-title`} id={`movie-item-title-${item.id}`}>{item.title}<div className={`movie-item-title-cover`}></div></div>
				<div className={`movie-item-image`}>
					{ isView &&
						<video ref={ref} className={`movie-item-video`} src={`${params.sampleMovielUrl}#t=0.001`} poster={params.images[ 0 ].src} preload={`auto`} loop muted playsInline controls autoPlay />
					}

					{/* { isView &&
						<div style={style1}>
							<iframe width={params.imageWidth} height={params.imageHeight} style={style2} src={`${params.sampleMovielUrl}#t=0.001`} scrolling="no" frameBorder="0" allowFullScreen>
							</iframe>
						</div>
					} */}

					<img className={`movie-item-package-image`} src={item.package_image_detail_url} alt={``} loading={`lazy`} content-visibility={`auto`} onClick={params.onClickDetail} />
				</div>
				<InView className={`read-threshold`} onChange={onObserverChanged} />
				{ 0 < params.photos.length &&
					<div className={`movie-item-photos`}>{params.photos}</div>
				}
				<div className={`movie-item-description`} id={`movie-item-description-${item.id}`}>{item.description}<div className={`movie-item-description-cover`}></div></div>
				<div className={`movie-package-image`}>
					<div className={`movie-package-cover`}></div>
				</div>
				<div className={`movie-item-button-base`}>
					<button className={`movie-item-button`} onClick={params.onClickDetail}>動画の詳細を見る</button>
				</div>
			</div>
			<Viewer
				visible={params.showViewer}
				activeIndex={params.activeIndex}
				downloadable={true}
				rotatable={false}
				scalable={false}
				attribute={false}
				loop={false}
				defaultScale={1.5}
				zoomSpeed={0.5}
				minScale={1.0}
				onClose={params.onClickClose}
				images={params.images}
				/>
		</div>
	);
});

const Style = ( params: any ) => css`
	display						: flex;
	justify-content				: center;
	position					: relative;
	width						: 100%;
	height						: 100%;		/* 100vh はアドレスバー分が隠れるのでだめ */
	margin						: 0px;
	border-width				: 1px;
	border-color				: lightgray;
	border-top-style			: solid;
	font-size					: 14px;
	background-color			: ${COLOR_EXTRA_LIGHT_GRAY};
	content-visibility			: auto;
	contain-intrinsic-size		: ${getClientHeight()}px;

	.movie-item-base
	{
		position				: relative;
		top						: 0px;
		width					: 100%;
		max-width				: ${CONTENT_WIDTH}px;
		height					: 100%;		/* 100vh はアドレスバー分が隠れるのでだめ */
		overflow-x				: hidden;
		overflow-y				: auto;
		background-color		: ${COLOR_ULTRA_LIGHT_GRAY};
		padding-top				: ${HEADER_HEIGHT}px;
	}
	.movie-item-image
	{
		position				: relative;
	}
	.movie-item-video
	{
		position				: relative;
		width					: 100%;
		width					: ${params.imageWidth}px;
		height					: ${params.imageHeight}px;
		object-fit				: cover;
		aspect-ratio			: 1.7;
		content-visibility		: auto;
		contain-intrinsic-size	: ${params.imageHeight}px;
	}
	.movie-item-package-image
	{
		position				: absolute;
		@media screen and ( max-width : calc( ${CONTENT_WIDTH}px - 1px ) )
		{
			width				: 100px;
			left				: 8px;
			top					: 8px;
		}
		@media screen and ( min-width : ${CONTENT_WIDTH}px )
		{
			width				: 200px;
			left				: 10px;
			top					: 10px;
		}
		height					: auto;
		border-radius			: 4px;
		aspect-ratio			: 1.7;
		object-fit				: cover;
		cursor					: pointer;
	}
	.movie-item-title
	{
		position				: relative;
		padding-left			: var( --sidePadding );
		padding-right			: var( --sidePadding );
		padding-top				: 16px;
		padding-bottom			: 16px;
		@media screen and ( max-width : calc( ${CONTENT_WIDTH}px - 1px ) )
		{
			font-size			: 24px;
			line-height			: 32px;
		}
		@media screen and ( min-width : ${CONTENT_WIDTH}px )
		{
			font-size			: 24px;
			line-height			: 32px;
		}
		font-weight				: bold;
		font-family				: 'M PLUS 1', sans-serif;
		text-align				: center;
		background				: linear-gradient( -90deg, ${COLOR_GRADATION}, ${COLOR_FANZTOK_MAIN} );
		color					: white;
		overflow	 			: hidden;
		text-overflow			: ellipsis;
		display					: -webkit-box;
		-webkit-box-orient		: vertical;
		-webkit-line-clamp		: 5;
	}
	.movie-item-description
	{
		position				: relative;
		padding-left			: var( --sidePadding );
		padding-right			: var( --sidePadding );
		padding-top				: 16px;
		padding-bottom			: 16px;
		overflow	 			: hidden;
		text-overflow			: ellipsis;
		display					: -webkit-box;
		-webkit-box-orient		: vertical;
		-webkit-line-clamp		: 4;
	}
	.movie-item-description-cover
	{
		position				: absolute;
		bottom					: 0px;
		width					: 100%;
		height					: 15px;
		background-color		: ${COLOR_ULTRA_LIGHT_GRAY};
	}
	.movie-item-title-cover
	{
		position				: absolute;
		bottom					: 0px;
		width					: 100%;
		height					: 15px;
		background				: linear-gradient( -90deg, ${COLOR_GRADATION}, ${COLOR_FANZTOK_MAIN} );
	}
	.movie-item-photos
	{
		position				: relative;
		height					: 70px;
		margin					: 0;
		overflow-x				: auto !important;
		overflow-y				: hidden;
		white-space				: nowrap;
		content-visibility		: auto;
		contain-intrinsic-size	: 70px;
	}
	.movie-item-photo-image
	{
		position				: relative;
		height					: 70px;
		aspect-ratio			: 16 / 10;
		object-fit				: cover;
		cursor					: pointer;
		transform				: scale( 1.0 );
		transition				: all 0.1s 0s ease;
	}
	.movie-item-photo-image:not(:last-of-type)
	{
		margin-right			: 8px;
	}
	.movie-item-photo-image:hover
	{
		border-radius			: 6px;
		transform				: scale( 1.05 );
	}
	.movie-package-image
	{
		position				: absolute;
		top						: ${params.imageOffset}px;
		width					: 100%;
		max-width				: min( ${CONTENT_WIDTH}px, var( --inner-width ) );
		height					: 240px;
		background-image		: url( ${params.packageImageUrl} );
	}
	.movie-package-cover
	{
		position				: absolute;
		bottom					: 0px;
		width					: 100%;
		max-width				: min( ${CONTENT_WIDTH}px, var( --inner-width ) );
		height					: 240px;
		backdrop-filter			: blur( 3px );
	}
	.movie-item-button-base
	{
		position				: absolute;
		left					: calc( 50% - 200px / 2 );
		top						: calc( var( --inner-height ) - 60px );
	}
	.movie-item-button
	{
		width					: 200px;
		height					: 42px;
		font-weight				: bold;
		font-size				: 14px;
		background-color		: ${COLOR_FANZTOK_MAIN};
		color					: white;
		transform				: scale( 1.0 );
		transition				: all 0.1s 0s ease;
	}
	.movie-item-button:hover
	{
		transform				: scale( 1.03 );
	}
`;

export const HomeMovieItem = ( props: any ) =>
{
	const state = useLocalState(
	{
		imageWidth		: Math.min( getClientWidth(), CONTENT_WIDTH ),
		imageHeight		: Math.min( getClientWidth(), CONTENT_WIDTH ) / 1.7,
		photos			: [],
		images			: [],
		showViewer		: false,
		activeIndex		: 0,
		timeline		: null,
		sampleMovielUrl	: ``,
		imageOffset		: 0,
	});
	const update = useUpdate();

	const onSwipe = useSwipeable(
	{
		onSwiped : async ( event: any ) =>
		{
			if( event.dir === `Left` || event.dir === `Right` )
			{
				return;
			}
			props.onSwipe( event.dir === `Up` ? `forward` : `backward` );
		},
		trackMouse: true,
	});

	const onClickDetail = useCallback( ( e: any ) =>
	{
		navigate( `/detail?id=${props.item.id}`, { state: { id: props.item.id }, replace: false } );
	}, [] );

	const onClickPhoto = useCallback( ( e: any ) =>
	{
		e.stopPropagation();
		state.activeIndex = toInteger( e?.currentTarget?.dataset?.index ) + 1;
		state.showViewer  = true;
		update();
	}, [ state.showViewer, state.activeIndex, update ] );

	const onClickClose = useCallback( () =>
	{
		state.showViewer = false;
		update();
	}, [ state.showViewer, update ] );

	const changeWindowSizeEnd = useCallback( ( params: any ) =>
	{
		state.imageWidth = Math.min( getClientWidth(), CONTENT_WIDTH );
		update();
	}, [ state.imageWidth, update ] );

	useEventSubscribe(
	[
		[ `CHANGE_WINDOW_SIZE_END`, changeWindowSizeEnd ],
	]);

	const load = useCallback( async () =>
	{
		state.images.push( { src: props.item.package_image_detail_url } );
		for( let i = 0; i < props.item.sample_image_urls.length; i++ )
		{
			const url = props.item.sample_image_urls[ i ];
			if( url.includes( `-${i+1}` ) )
			{
				state.images.push( { src: url.replace( `-${i+1}`, `jp-${i+1}` ) } );
			}
			else
			{
				state.images.push( { src: url } );
			}
		}
		state.photos = [];
		for( const url of props.item.sample_image_urls )
		{
			state.photos.push( <img key={getUniqueKey( false )} className={`movie-item-photo-image`} src={url} alt={``} data-index={state.photos.length} loading={`lazy`} content-visibility={`auto`} onClick={onClickPhoto} /> );
		}

		state.imageHeight = state.imageWidth / 1.7;

		// 高画質版のパスに変更
		state.sampleMovielUrl = props.item.sample_moviel_url.replace( `sm_w`, `dmb_w` );
		update();

		const title = document.getElementById( `movie-item-title-${props.item.id}` );
		const descs = document.getElementById( `movie-item-description-${props.item.id}` );
		if( title && descs )
		{
			const height1 = Number( window.getComputedStyle( title ).height.replace( `px`, `` ) );
			const height2 = 0 < state.photos.length ? 70 : 0;
			const height3 = Number( window.getComputedStyle( descs ).height.replace( `px`, `` ) );
			state.imageOffset = HEADER_HEIGHT + height1 + state.imageHeight + height2 + height3 + 70;
		}
	}, [ state ] );

	useMount( () =>
	{
		load();
	});

	const styleParams =
	{
		imageWidth		: state.imageWidth,
		imageHeight		: state.imageHeight,
		packageImageUrl	: props.item.package_image_detail_url,
		imageOffset		: state.imageOffset,
	};
	const params =
	{
		style			: Style( styleParams ),
		item			: props.item,
		imageWidth		: state.imageWidth,
		imageHeight		: state.imageHeight,
		sampleMovielUrl	: state.sampleMovielUrl,
		photos			: state.photos,
		showViewer		: state.showViewer,
		images			: state.images,
		activeIndex		: state.activeIndex,
		timeline		: state.timeline,
		onClickDetail	: onClickDetail,
		onSwipe			: onSwipe,
		onClickClose	: onClickClose,
	};
	return ( <Layout {...params} /> );
};
