import { css } from '@emotion/react';
import { useUpdate, useMount } from 'react-use';
import PropTypes from 'prop-types';
import { useRef, useCallback } from 'react';
import { isIOS } from 'react-device-detect';

import
{
	SCROLL_HANDLE_WIDTH,
} from '../utility/const';
import
{
	sleep,
	getClientHeight,
	waitUntil,
} from '../utility/utility';
import { useEventSubscribe } from '../hook/use-event-subscribe';
import { useLocalState } from '../hook/use-local-state';

const Layout = ( params: any ) =>
{
	const ref = useRef< any >();

	if( ref && ref.current )
	{
		params.onRendered( ref.current );
	}

	const state = useLocalState(
	{
		scrollTop	 : -1,
		scrollHeight : -1,
		isWait		 : false,
	});

	const onScroll = ( e: any ) =>
	{
		params.onScroll?.( e.currentTarget, e.currentTarget.scrollTop - state.scrollTop < 0 ? `backward` : `forward` );
		const scrollTop	   = Math.floor( e.currentTarget.scrollTop );
		const scrollHeight = Math.floor( e.currentTarget.scrollHeight );
		if( state.scrollTop !== -1 )
		{
			if( scrollHeight === state.scrollHeight )
			{
				if( scrollTop <= params.threshold )
				{
					if( scrollTop < state.scrollTop )
					{
						params.onTop();
					}
				}
				else if( scrollHeight - Math.floor( params.height ) - params.threshold <= scrollTop + 1 )
				{
					if( state.scrollTop < scrollTop )
					{
						params.onBottom();
					}
				}
			}
		}
		state.scrollTop	   = scrollTop;
		state.scrollHeight = scrollHeight;
	};

	return (
		<div css={params.style}>
			<div ref={ref} className={`scroll-outer`} onScroll={onScroll}>
				<div className={`scroll-inner`} id={`scroll-inner-${params.id}`}>
					{params.items}
				</div>
			</div>
		</div>
	);
};

const Style = ( params: any ) => css`
	position				: relative;
	left					: 0px;
	top						: 0px;
	width					: 100%;
	height					: ${params.height}px;
	padding					: 0px !important;
	margin					: 0px !important;
	opacity					: ${params.opacity};

	.scroll-outer
	{
		position			: relative;
		left				: 0px;
		top					: 0px;
		width				: 100%;
		height				: ${params.height}px;
		overflow-x			: hidden;
		overflow-y			: auto;
		padding				: 0px !important;
		margin				: 0px !important;
		background-color	: ${params.bgColor};
		--scrollBarColor	: white;
		will-change			: scroll-position;
	}
	.scroll-outer::-webkit-scrollbar-track
	{
		background-color	: ${params.bgColor};
	}
	.scroll-outer::-webkit-scrollbar-thumb
	{
		background-color	: var( --scrollBarColor );
		border-radius		: 5px;
	}
	.scroll-outer::-webkit-scrollbar
	{
		width				: ${params.thumbWidth}px;
	}
	.scroll-outer:hover
	{
		--scrollBarColor	: #D4D4D4;
	}
	.scroll-inner
	{
		position			: relative;
		left				: 0px;
		top					: 0px;
		height				: 100%;
		list-style			: none;
		padding				: 0px !important;
		margin				: 0px !important;
		will-change			: scroll-position;
	}
	.scroll-item
	{
		position			: relative;
		left				: 0px;
		top					: 0px;
		width				: 100%;
		/* height				: 100%; これを指定してはいけない */
	}
	.no-data-text
	{
		color				: gray !important;
		text-align			: center;
	}
	.ui.dimmer
	{
		position			: relative !important;
		top					: -100% !important;
	}
	.scroll-view-sign
	{
		position			: absolute;
		top					: 50%;
		left				: 50%;
		transform			: translate( -50%, -50% );
	}
`;

export const ScrollList = ( props: any ) =>
{
	const state = useLocalState(
	{
		ref				: null,
		opacity			: props.startPos === `bottom` ? 0.0 : 1.0,
		isCacheLoad		: props.useCache,
		isAutoScroll	: false,
		isRestoreScroll	: false,
		isFirst			: true,
		isWait			: false,
		isSwipeWait		: false,
		scrollBehavior	: `auto`,
		data			: [],
		hash			: {},
		items			: [],
		scrollHeight	: 0,
		saveData		: null,
		saveItem		: null,
	});
	const update = useUpdate();

	const enableScroll = useCallback( async ( params: any ) =>
	{
		const f = async ( e: any ) =>
		{
			if( Math.abs( e.deltaY ) < Math.abs( e.deltaX ) )
			{
				return;
			}
			if( state.isSwipeWait )
			{
				if( Math.abs( e.deltaY ) <= 25 )
				{
					state.isSwipeWait = false;
				}
				else
				{
					e.preventDefault();
					e.stopPropagation();
					return;
				}
			}
			if( 50 < Math.abs( e.deltaY ) )
			{
				props.onSwipe?.( e.deltaY < 0 ? `backward` : `forward` );
				state.isSwipeWait = true;
			}
			e.preventDefault();
			e.stopPropagation();
		};

		const scrollRef: any = state.ref;
		if( params.enable )
		{
			scrollRef?.removeEventListener( `touchmove`	, f );
			scrollRef?.removeEventListener( `wheel`		, f );
		}
		else
		{
			scrollRef?.addEventListener( `touchmove`	, f, { passive: false } );
			scrollRef?.addEventListener( `wheel`		, f, { passive: false } );
		}
	}, [ state.ref ] );

	const loadItems = useCallback( async ( direction: string, useCache: boolean ) =>
	{
		const scrollRef: any = state.ref;
		state.scrollHeight = scrollRef ? scrollRef.scrollHeight : 0;
		state.isWait = true;
		const { mode, data } = await props.onLoad( direction, useCache );

		setTimeout( () =>
		{
			state.isWait  = false;
			state.isFirst = false;
			update();
		}, 500 );

		if( mode === `none` || mode === `wait` )
		{
			props.onLoaded?.( mode, 0, 0 );
			return { mode: `none`, count: 0, oldCount: 0 };
		}

		const oldCount = state.items.length;
		if( mode === `append` )
		{
			if( direction === `prev` )
			{
				state.data = [ ...data, ...state.data ];
			}
			else // init, next
			{
				state.data = [ ...state.data, ...data ];
			}
		}
		else // override
		{
			state.data  = [ ...data ];
			state.items = [];
		}
		data.map( ( item: any, index: number ) => { state.hash[ item.id ] = index; } );	// eslint-disable-line array-callback-return
		props.onLoaded?.( mode, data.length, oldCount );
		return { mode: mode, count: data.length, oldCount: oldCount };
	}, [ props, state.data, state.items, state.hash, state.scrollHeight, state.isWait, state.isFirst, update ] );

	const waitScrollToBottom = useCallback( async ( height: number ) =>
	{
		const scrollRef: any = state.ref;
		for( let i = 0; i < 100; i++ )
		{
			if( scrollRef && height < scrollRef.scrollHeight )
			{
				break;
			}
			await sleep( 10 );
		}
	}, [] );

	const scrollToBottom = useCallback( async ( behavior: string ) =>
	{
		const scrollRef: any = state.ref;
		if( scrollRef )
		{
			scrollRef.scrollTo( { left: 0, top: scrollRef.scrollHeight, behavior: behavior } );
		}
	}, [] );

	const autoScrollToButtom = useCallback( async ( direction: string ) =>
	{
		if( ( ( state.isAutoScroll || direction === `init` ) && state.isWait === false ) || direction === `force` )
		{
			await sleep( 0 );
			scrollToBottom( direction === `init` ? `auto` : state.scrollBehavior );
		}
	}, [ state.isAutoScroll, state.isWait, state.scrollBehavior, scrollToBottom ] );

	const renderItems = useCallback( async ( direction: string, start: number, end: number, isModify: boolean ) =>
	{
		const items = [];
		for( let i = start; i < end; i++ )
		{
			const data = state.data[ i ];
			items.push( props.onRenderItem( i, data, isModify, props.id ) );
		}
		if( direction === `prev` )
		{
			state.items = [ ...items, ...state.items ];
		}
		else // init, next
		{
			state.items = [ ...state.items, ...items ];
		}
		update();

		const scrollRef: any = state.ref;
		if( props.startPos === `bottom` )
		{
			if( state.isRestoreScroll === true )
			{
				if( isIOS )
				{
					// ios の場合は表示物が存在するが表示がされない問題があるため無理やり回避させる
					// 試行錯誤した結果 opacity を変更し、auto で 2 ピクセル手前まで移動、その後 smooth で目的位置に移動し opacity を戻す
					// update() と sleep() もそれぞれ必要
					state.opacity = 0.0;
					update();
					const target = scrollRef.scrollHeight - state.scrollHeight;
					scrollRef.scrollTo( { left: 0, top: target - 2, behavior: `auto` } );
					await sleep( 0 );
					scrollRef.scrollTo( { left: 0, top: target, behavior: `smooth` } );
					state.opacity = 1.0;
					update();
				}
				else
				{
					scrollRef.scrollTo( { left: 0, top: scrollRef.scrollHeight - state.scrollHeight, behavior: `auto` } );
				}
				state.isRestoreScroll = false;
			}
			else
			{
				autoScrollToButtom( direction );
			}
		}
		props.onRendered?.( scrollRef );
	}, [ props, state.data, state.items, state.scrollHeight, state.isRestoreScroll, state.opacity, update, autoScrollToButtom ] );

	const loadPrev = useCallback( async () =>
	{
		state.isRestoreScroll = true;
		const { mode, count } = await loadItems( `prev`, false );
		if( mode !== `none` && mode !== `wait` )
		{
			renderItems( `prev`, 0, count, false );
		}
	}, [ state.isRestoreScroll, loadItems, renderItems ] );

	const loadNext = useCallback( async () =>
	{
		const length = state.data.length;
		const { mode, count } = await loadItems( `next`, false );
		if( mode === `append` )
		{
			renderItems( `next`, length, length + count, false );
		}
		else if( mode === `override` )
		{
			renderItems( `next`, 0, count, false );
		}
	}, [ state.data.length, loadItems, renderItems ] );

	const scrollView = useCallback( async ( params: any ) =>
	{
		const behavior = params.behavior === undefined ? `auto` : params.behavior;
		if( behavior === `smooth` )
		{
			state.isWait = true;
			setTimeout( () =>
			{
				state.isWait = false;
			}, 1000 );	// 当てで設定してある
		}

		const scrollRef: any = state.ref;
		if( params.mode === `top` )
		{
			scrollRef.scrollTo( { left: 0, top: 0, behavior: behavior } );
			return;
		}
		if( params.mode === `bottom` )
		{
			scrollRef.scrollTo( { left: 0, top: scrollRef.scrollHeight, behavior: behavior } );
			return;
		}

		let index = state.hash[ params.id ];
		if( params.isWait )
		{
			state.isWait = true;
			await waitUntil( () =>
			{
				index = state.hash[ params.id ];
				return index === undefined;
			}, 1 );
			await sleep( 10 );
		}
		if( index === undefined )
		{
			props.onNoScrollItem( params );
			return;
		}
		params.index = index;

		const component = document.getElementById( params.id );
		if( component === null )
		{
			return;
		}
		component.scrollIntoView( { behavior: behavior } );

		if( params.isFlash )
		{
			await sleep( 100 );
			const contentNode = document.getElementById( params.id );
			if( contentNode !== null )
			{
				contentNode.classList.remove( `selected` );
				await sleep( 100 );
				contentNode.classList.add( `selected` );
			}
		}
	}, [ props, state.hash, state.isWait, update ] );

	const setAutoScroll = useCallback( ( params: any ) =>
	{
		if( props.startPos === `bottom` )
		{
			state.isAutoScroll	 = params.enable;
			state.scrollBehavior = params.behavior;
		}
	}, [ props.startPos, state.isAutoScroll, state.scrollBehavior ] );

	const updateItem = useCallback( ( params: any ) =>
	{
		const index = state.hash[ params.id ];
		if( index === undefined )
		{
			return;
		}
		state.data[ index ]  = { id: state.data[ index ].id, data: params.data };
		state.items[ index ] = props.onRenderItem( index, state.data[ index ], true );
		update();
	}, [ props, state.items, state.hash, state.data, update ] );

	const addItem = useCallback( async ( params: any ) =>
	{
		const scrollRef: any = state.ref;
		const index = state.data.length;
		const data  = { id: params.id, data: params.data };
		state.data.push( data );
		state.items.push( props.onRenderItem( index, data, false ) );
		await waitScrollToBottom( scrollRef.scrollHeight );
		autoScrollToButtom( `force` );
	}, [ props, state.items, state.data, waitScrollToBottom, autoScrollToButtom ] );

	const removeItem = useCallback( ( params: any ) =>
	{
		const dataIndex = state.data.findIndex( ( item: any ) => params.id === item.id );
		const itemIndex = state.items.findIndex( ( item: any ) => item.props.item ? item.props.item.id : `` );
		if( dataIndex === -1 || itemIndex === -1 )
		{
			return;
		}
		state.saveData = { index: itemIndex, data: state.data[ dataIndex ] };
		state.saveItem = { index: itemIndex, item: state.items[ itemIndex ] };
		state.data.splice( dataIndex, 1 );
		state.items.splice( itemIndex, 1 );
		update();
	}, [ state.data, state.items, state.saveData, state.saveItem, update ] );

	const restoreItem = useCallback( ( params: any ) =>
	{
		if( state.saveData === null )
		{
			return;
		}
		state.data.splice( state.saveData.index, 0, state.saveData.data );
		state.items.splice( state.saveItem.index, 0, state.saveItem.item );
		state.saveData = null;
		state.saveItem = null;
		update();
	}, [ state.data, state.items, state.saveData, state.saveItem, update ] );

	const renderView = useCallback( ( params: any ) =>
	{
		state.data  = [ ...params.data ];
		state.hash	= {};
		state.items = [];
		const count = state.data.length;
		params.data.map( ( item: any, index: number ) => { state.hash[ item.id ] = index; } );	// eslint-disable-line array-callback-return
		if( params.direction === `prev` )
		{
			renderItems( `prev`, 0, count, true );
		}
		else
		{
			renderItems( `next`, 0, count, true );
		}
	}, [ state.data, state.hash, state.items, renderItems ] );

	const onTop = useCallback( () =>
	{
		if( state.isWait )
		{
			return;
		}
		loadPrev();
	}, [ state.isWait, loadPrev ] );

	const onBottom = useCallback( () =>
	{
		if( state.isWait )
		{
			return;
		}
		loadNext();
	}, [ state.isWait, loadNext ] );

	const onRendered = useCallback( ( ref: any ) =>
	{
		state.ref = ref;
	}, [ state.ref ] );

	const onSwipe = useCallback( ( direction: string ) =>
	{
		props.onSwipe?.( direction );
	}, [ props.onSwipe ] );

	useEventSubscribe(
	[
		[ `ENABLE_SCROLL_LIST`		, enableScroll	, props.id ],
		[ `LOAD_PREV_SCROLL_LIST`	, loadPrev		, props.id ],
		[ `LOAD_NEXT_SCROLL_LIST`	, loadNext		, props.id ],
		[ `SCROLL_SCROLL_LIST`		, scrollView	, props.id ],
		[ `SET_AUTO_SCROLL_LIST`	, setAutoScroll	, props.id ],
		[ `UPDATE_ITEM_SCROLL_LIST`	, updateItem	, props.id ],
		[ `ADD_ITEM_SCROLL_LIST`	, addItem		, props.id ],
		[ `REMOVE_ITEM_SCROLL_LIST`	, removeItem	, props.id ],
		[ `RESTORE_ITEM_SCROLL_LIST`, restoreItem	, props.id ],
		[ `RENDER_SCROLL_LIST`		, renderView	, props.id ],
	]);

	useMount( async () =>
	{
		const { count } = await loadItems( `init`, false );
		if( 0 < count )
		{
			renderItems( `init`, 0, state.data.length, false );
		}
		else
		{
			update();
		}
		if( props.startPos === `bottom` )
		{
			await waitScrollToBottom( getClientHeight() );
			scrollToBottom( `auto` );
			state.opacity = 1.0;
			update();
		}
	});

	const f = async () =>
	{
		if( state.isCacheLoad )
		{
			state.isCacheLoad = false;
			const { count } = await loadItems( `init`, true );
			if( 0 < count )
			{
				renderItems( `init`, 0, state.data.length, false );
			}
		}
	};
	f();

	const styleParams =
	{
		height		: props.height,
		itemHeight	: props.itemMinHeight,
		opacity		: state.opacity,
		bgColor		: props.bgColor,
		thumbWidth	: props.useSwipe ? 0 : SCROLL_HANDLE_WIDTH,
	};
	const params =
	{
		style		: Style( styleParams ),
		id			: props.id,
		height		: props.height,
		threshold	: props.threshold,
		useSwipe	: props.useSwipe,
		ref			: state.ref,
		items		: state.items,
		isWait		: state.isWait,
		isFirst		: state.isFirst,
		onScroll	: props.onScroll,
		onTop		: onTop,
		onBottom	: onBottom,
		onRendered	: onRendered,
		onSwipe		: onSwipe,
	};
	return ( <Layout {...params} /> );
};

ScrollList.propTypes =
{
	id				: PropTypes.string.isRequired,
	startPos		: PropTypes.oneOf( [ `top`, `bottom` ] ),
	useCache		: PropTypes.bool,
	useSwipe		: PropTypes.bool,
	height			: PropTypes.number,
	threshold		: PropTypes.number,
	onLoad			: PropTypes.func.isRequired,
	onRenderItem	: PropTypes.func.isRequired,
	onNoScrollItem	: PropTypes.func.isRequired,
	bgColor			: PropTypes.string,
	onScroll		: PropTypes.func,
	onSwipe			: PropTypes.func,
	onLoaded		: PropTypes.func,
	onRendered		: PropTypes.func,
};

ScrollList.defaultProps =
{
	startPos	: `bottom`,
	useCache	: false,
	useSwipe	: false,
	height		: 400,
	threshold	: 0,
	bgColor		: `white`,
	onScroll	: null,
	onSwipe		: null,
	onLoaded	: null,
	onRendered	: null,
};
