import { createClient } from '@supabase/supabase-js'
import { isAndroid, isIOS } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import HashMap from 'hashmap';
import parse from 'fast-json-parse';
import stringify from 'fast-json-stable-stringify';

// import
// {
// 	BREAKPOINT_SP,
// } from './const';

const supabaseUrl	  = `https://xjnjglcvxycftafnqnlf.supabase.co`;
const supabaseAnonKey = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhqbmpnbGN2eHljZnRhZm5xbmxmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njg0MTA1MTgsImV4cCI6MTk4Mzk4NjUxOH0.M8Q-JR-rSM1LeYuMjy5t1T6Otd2i0Z9MVSmukhNXCEA`;
export const supabase = createClient( supabaseUrl, supabaseAnonKey );
let uniqueKeyBase	  = uuidv4();
let uniqueKeyCounter  = 0;
let memoryStorage	  = new HashMap();

export const jsonToString = ( json: any ) =>
{
	// return JSON.stringify( json );
	return stringify( json );
};

export const stringToJson = ( string_: string ) =>
{
	// return JSON.parse( string_ );
	const result = parse( string_ );
	if( result.err )
	{
		console.log( `JSON parse エラー` )
		console.log( result.err.message )
		return {};
	}
	return result.value;
};

export const shuffleArray = ( arr: Array< any > ) =>
{
	let i, j, temp;
	arr = arr.slice();
	i = arr.length;
	if( i === 0 )
	{
		return arr;
	}
	while( --i )
	{
		j = Math.floor( Math.random() * ( i + 1 ) );
		temp = arr[ i ];
		arr[ i ] = arr[ j ];
		arr[ j ] = temp;
	}
	return arr;
};

export const toNumber = ( str: any ) =>
{
	return Number( str );
}

export const toInteger = ( str: any ) =>
{
	return parseInt( str );
}

export const isBrower = () =>
{
	return typeof window !== `undefined`;
};

export const isSmartphone = () =>
{
	return isAndroid || isIOS;
}

export const randomRangeInteger = ( min: number, max: number ) =>	// min <= N < max
{
	return Math.floor( Math.random() * ( max - min ) + min );
};

export const sleep = ( msec: number ) =>
{
	return new Promise<void>( ( resolve ) =>
	{
		setTimeout( () => { resolve() }, msec );
	});
};

export const waitUntil = async ( condition: any, wait = 0, retry = 0 ) =>
{
	if( condition() === false )
	{
		return;
	}
	return new Promise( ( resolve: any ) =>
	{
		let count = 0;
		const id = setInterval( () =>
		{
			const until = retry === 0 || ++count <= retry;
			if( condition() === false || until === false )
			{
				clearInterval( id );
				resolve()
			}
		}, wait );
	});
};

export const getLocalStorage = ( item: string ) =>
{
	if( isBrower() === true )
	{
		return localStorage.getItem( item );
	}
	return null;
};
export const setLocalStorage = ( item: string, value: string ) =>
{
	if( isBrower() === true )
	{
		localStorage.setItem( item, value );
	}
};
export const removeLocalStorage = ( item: string ) =>
{
	if( isBrower() === true )
	{
		localStorage.removeItem( item );
	}
};

export const getSessionStorage = ( item: string ) =>
{
	if( isBrower() === true )
	{
		return sessionStorage.getItem( item );
	}
	return null;
};
export const setSessionStorage = ( item: string, value: string ) =>
{
	if( isBrower() === true )
	{
		sessionStorage.setItem( item, value );
	}
};
export const removeSessionStorage = ( item: string ) =>
{
	if( isBrower() === true )
	{
		sessionStorage.removeItem( item );
	}
};

export const setMemoryStorage = ( item, value ) =>
{
	memoryStorage.set( item, value );
};
export const getMemoryStorage = ( item ) =>
{
	const value = memoryStorage.get( item );
	if( value !== undefined )
	{
		return value;
	}
	return undefined;
};
export const removeMemoryStorage = ( item ) =>
{
	const value = memoryStorage.get( item );
	if( value !== undefined )
	{
		memoryStorage.delete( item );
	}
};
export const clearMemoryStorage = () =>
{
	memoryStorage = new HashMap();
	// sendToServiceWorker( `clear-memory-cache`, `` );
};

export const parseUrlParams = () =>
{
	const params = [];
	if( isBrower() === true )
	{
		const urlParams = new URLSearchParams( window.location.search );
		for( let pair of urlParams.entries() )
		{
			params.push( { key: pair[ 0 ], value: pair[ 1 ] } );
		}
	}
	return params;
};

export const getUrlParams = ( key: string ) =>
{
	const result = parseUrlParams().find( ( item ) => item.key === key );
	if( result !== undefined )
	{
		return result.value;
	}
	return undefined;
};

export const getClientWidth = () =>
{
	if( isBrower() === true )
	{
		return document.body.clientWidth;
	}
	return 0;
};

export const getClientHeight = () =>
{
	if( isBrower() === true )
	{
		return document.body.clientHeight;
	}
	return 0;
};

export const getUniqueKey = ( isReset: boolean ) =>
{
	if( isReset )
	{
		uniqueKeyBase	 = uuidv4();
		uniqueKeyCounter = 0;
	}
	return `${uniqueKeyBase}${uniqueKeyCounter++}`;
};

export const supabaseSelectData = async ( table: string, key: string, value: any ) =>
{
	const { data, error } = await supabase.from( table ).select().eq( key, value );
	if( error !== null )
	{
		if( import.meta.env.DEV )
		{
			console.log( `supabaseSelectData: error: `, error )
		}
		return [];
	}
	return data;
};

export const supabaseSelectText = async ( table: string, key: string, value: any ) =>
{
	const { data, error } = await supabase.from( table ).select().textSearch( key, value );
	if( error !== null )
	{
		if( import.meta.env.DEV )
		{
			console.log( `supabaseSelectText: error: `, error )
		}
		return [];
	}
	return data;
};

const supabaseGetFanzaMovie = async ( id: number ) =>
{
	const { data, error } = await supabase.rpc( `get_fanza_movie`, { id: id } );
	if( error !== null )
	{
		if( import.meta.env.DEV )
		{
			console.log( `supabaseGetFanzaMovie: error: `, error )
		}
		return ``;
	}
	if( data.length === 0 )
	{
		return ``;
	}
	return data[ 0 ];
};

export const getFanzaMovie = async ( key: string, id: number ) =>
{
	const cache = getSessionStorage( key );
	if( cache !== null )
	{
		return stringToJson( cache as string );
	}

	const data = await supabaseGetFanzaMovie( id );
	if( data === `` || data.sample_image_urls.length === 0 )
	{
		return ``;
	}
	setSessionStorage( key, jsonToString( data ) );
	return data;
};

export const supabaseGetFanzaGoodMovies = async ( good_level: number, categoryId: number, startId: number, count: number ) =>
{
	let func   = ``;
	let params = {};
	if( 0 < categoryId )
	{
		func   = `get_fanza_good_category_movies`;
		params = { good_level: good_level, category_id: categoryId, start_id: startId, item_count: count };
	}
	else
	{
		func   = `get_fanza_good_movies`;
		params = { good_level: good_level, start_id: startId, item_count: count };
	}
	const { data, error } = await supabase.rpc( func, params );
	if( error !== null )
	{
		if( import.meta.env.DEV )
		{
			console.log( `supabaseGetFanzaGoodMovies: error: `, error )
		}
		return [];
	}
	if( data.length === 0 )
	{
		return [];
	}
	return data;
};

export const supabaseGetFanzaActoressInfo = async ( fanza_actoress_id: number ) =>
{
	const { data, error } = await supabase.rpc( `get_fanza_actoress_info`, { id: fanza_actoress_id } );
	if( error !== null )
	{
		if( import.meta.env.DEV )
		{
			console.log( `supabaseGetFanzaActoressInfo: error: `, error )
		}
		return [];
	}
	if( data.length === 0 )
	{
		return [];
	}
	return data;
};

export const getCategoryList = async ( type: string, offset ) =>
{
	// const cache = getSessionStorage( `category-${type}` );
	// if( cache !== null )
	// {
	// 	return stringToJson( cache as string );
	// }
	const { data, error } = await supabase.from( `fanza_category` ).select().eq( `type`, type ).order( `item_count`, { ascending: false } ).range( offset, offset + 20 );
	// const { data, error } = await supabase.from( `category` ).select( `name, count, type_id` ).eq( `type`, type ).order( `count`, { ascending: false } ).limit( 20 );
	if( error !== null )
	{
		if( import.meta.env.DEV )
		{
			console.log( `getCategoryList: error: `, error )
		}
		return [];
	}
	if( data.length === 0 )
	{
		return [];
	}
	// setSessionStorage( `category-${type}`, jsonToString( data ) );
	return data;
};

export const getTimelineList = async ( site: string, typeId: number, startId: number, count: number ) =>
{
	const result = await supabase.from( `category_content` ).select( `id, type_id, content_id, description` ).eq( `type_id`, typeId ).order( `date`, { ascending: false } ).limit( count );
	if( result.error !== null )
	{
		if( import.meta.env.DEV )
		{
			console.log( `getTimelineList: error: `, result.error )
		}
		return [];
	}
	if( result.data.length === 0 )
	{
		return [];
	}
	return result.data;
};

export const getCategoryContent = async ( contentId: string ) =>
{
	const result = await supabase.from( `category_content` ).select( `id, type_id, content_id, description` ).eq( `content_id`, contentId ).limit( 1 );
	if( result.error !== null )
	{
		if( import.meta.env.DEV )
		{
			console.log( `getCategoryContent: error: `, result.error )
		}
		return [];
	}
	if( result.data.length === 0 )
	{
		return [];
	}
	return result.data;
};

export const getFanzaContentInfo = async ( content_id: string, description: string ) =>
{
	const url	   = `https://api.dmm.com/affiliate/v3/ItemList?api_id=FW1vTpUTtz70hnK3ta19&affiliate_id=fanztok-990&site=FANZA&service=digital&output=json&cid=${content_id}`;
	const response = await axios.get( url, { mode: `cors` } );
	const json	   = response.data;
	if( json.result.status !== 200 )
	{
		return {};
	}
	json.result.items[ 0 ].description = description;
	setMemoryStorage( content_id, jsonToString( json.result.items[ 0 ] ) );
	return json.result.items[ 0 ];
};

export const searchTweets = async ( screenName: string, start: number, end: number ) =>
{
	const { data, error } = await supabase.from( `twitter_tweet` ).select().eq( `screen_name`, screenName ).order( `date`, { ascending: false } ).range( start, end );
	if( error !== null )
	{
		if( import.meta.env.DEV )
		{
			console.log( `searchTweets: error: `, error )
		}
		return [];
	}
	return data;
};

export const upsertTag = async ( twitterTweetId: number, tags: [ string ],  tag: string ) =>
{
	const index = tags.findIndex( ( item ) => item === tag );
	if( index === -1 )
	{
		tags.push( tag );
	}
	else
	{
		tags.splice( index, 1 );
	}
	const { error } = await supabase.from( `twitter_tweet` ).update( { tags: tags } ).eq( `id`, twitterTweetId );
	if( error !== null )
	{
		if( import.meta.env.DEV )
		{
			console.log( `upsertTag: error: `, error )
		}
	}
};

export const getFanzaCoupon = async () =>
{
	const url	 = `https://sheets.googleapis.com/v4/spreadsheets/1WCLeIKrisyuWwz731rUvFeSUeVukYgvffPNGJLMURqw/values/coupon?key=AIzaSyDO-xCbCNedgzAQEEXsaiGnjpyeba3CErY`;
	const result = await fetch( url );
	const text	 = await result.text();
	const list	 = JSON.parse( text ).values;
	const items	 = [];
	for( let i = 1; i < list.length; i++ )
	{
		items.push(
		{
			no		: list[ i ][ 0 ],
			name	: list[ i ][ 1 ],
			keyword	: list[ i ][ 2 ],
			tag		: list[ i ][ 3 ],
		});
	}
	return items;
};
