import React, { useRef, useState, useEffect } from 'react';
import script from 'scriptjs';

// react-twitter-embed モジュールの追加だけ必要。これがなければロードに失敗する...

const methodName$5 = 'createTweet';

export const EmbedTweet = ( props: any ) =>
{
	const ref = useRef( null );
	const [ loading, setLoading ] = useState( true );

	useEffect( () =>
	{
		let isComponentMounted = true;

		script( `https://platform.twitter.com/widgets.js`, 'twitter-embed', () =>
		{
			if( !( window as any ).twttr )
			{
				// console.error( 'Failure to load window.twttr, aborting load' );
				return;
			}

			if( isComponentMounted )
			{
				const func = ( window as any ).twttr.widgets[ methodName$5 ];

				if( !func )
				{
					// console.error( `Method ${methodName$5} is not present anymore in twttr.widget api` );
					return;
				}

				const container = ref === null || ref === void 0 ? void 0 : ref.current;
				func( props.tweetId, container, props.options ).then( ( element: any ) =>
				{
					setLoading( false );
					if( props.onLoad )
					{
						props.onLoad( element );
					}
				});
			}
		});

		return () =>
		{
			setLoading( false );
			isComponentMounted = false;
		};
	}, [] );

	return React.createElement( React.Fragment, null, loading && React.createElement( React.Fragment, null, props.placeholder ), React.createElement( "div", { ref: ref } ) );
};
