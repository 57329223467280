import { css } from '@emotion/react';
import { memo, useCallback } from 'react';
import { useMount } from 'react-use';

import
{
	COLOR_ULTRA_LIGHT_GRAY,
	HEADER_HEIGHT,
} from '../utility/const';
import { Event } from '../utility/event';
import { TweetTimeline } from './tweet-timeline';

const Layout = memo( ( params: any ) =>
{
	return (
		<div css={params.style}>
			<div className={`tweet-timeline-layout`}>

{/* ４列をどうやって選んでくるか */}

				<TweetTimeline screenName={`reireikitarei`} />
				<TweetTimeline screenName={`ranran_ru808`} />
				<TweetTimeline screenName={`asahina_nanase`} />
				<TweetTimeline screenName={`nakayama_kotoha`} />
			</div>
		</div>
	);
});

const Style = ( params: any ) => css`
	position			: relative;
	width				: 100%;
	height				: 100vh;
	padding-left		: 8px;
	background-color	: ${COLOR_ULTRA_LIGHT_GRAY};

	.tweet-timeline-layout
	{
		display			: flex;
		position		: relative;
		top				: ${HEADER_HEIGHT}px;
	}
`;

export const Picker = memo( ( props: any ) =>
{
	const onClickOK = useCallback( () =>
	{
	}, [] );

	useMount( () =>
	{
		Event.publish( `TOGGLE_CONTENT_WIDTH`, { isFullContent: true }, true );
	});

	const styleParams =
	{
	};
	const params =
	{
		style		: Style( styleParams ),
		onClickOK	: onClickOK,

	};
	return ( <Layout {...params} /> );
});
